import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';
import {View, Text, StyleSheet, ActivityIndicator, TouchableOpacity} from 'react-native';
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';
import { COLORS, EDGES, SCREEN_SIZE, SERVER_URL, errorMessages, isDeviceOld, regexPatterns } from '../../Constants';
import { useEffect, useState } from 'react';
import CustomButton from '../../components/CustomButton';
import Fire from '../../Fire';
import catchErrorForSentry from '../../helpers/catchSentryError';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserData, setUserData, updateUserDataProperty } from '../../store/slices/userData';
import { Screen } from '../../components/Screen';
import ToggleSwitch from 'toggle-switch-react-native';
import { RootState } from '../../store';
import registerForPushNotificationsAsync from '../../helpers/registerPushNotificationsAsync';
import TouchableContainer from '../../components/TouchableContainer';
import { Ionicons } from '@expo/vector-icons';
import CustomModal from '../../components/CustomModal';
import ValidateInput from '../../components/ValidateInput';
import { UserData } from '../../Interfaces';
import deleteFromStorage from '../../helpers/deleteFromStorage';
import scaleFontSize from '../../helpers/scaleFontSize';
import { CommonActions } from '@react-navigation/native';
import confirmDecision from '../../helpers/confirmDecision';
import showToast from '../../helpers/showToast';
import showResetPasswordToast from '../../helpers/showResetPasswordToast';
import { sendPasswordResetEmail } from 'firebase/auth';
import isObjEmpty from '../../helpers/isObjEmpty';

const HamburgerMenu = (props: any) => {
    const { navigation } = props;
    const dispatch = useDispatch();
    const [processing, setProcessing] = useState(false);
    const userData = useSelector((state: RootState) => state.userData);
    const switchData = useSelector((state: RootState) => state.switchData);
    const [updatingAppNotifications, setUpdatingAppNotifications] = useState(false);
    const [pushNotificationsOn, setPushNotificationsOn] = useState(userData?.pushToken && userData.pushNotificationsOn);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false);
    const [confirmDeleteValue, setConfirmDeleteValue] = useState("");
    const [deletingAccount, setDeletingAccount] = useState(false);
    const [deleteError, setDeleteError] = useState("");
    const [updateEmailError, setUpdateEmailError] = useState(false);
    const [newEmail, setNewEmail] = useState("");

    const [uidsUserAdmins, setUidsUserAdmins] = useState(); 


    useEffect(()=>{
        console.log("Hambuger screen rendered");
        const onUidsUserAdminsChange = (uidsData: any)=>{
            if(!uidsData) return;
            console.log(`uidsUserAdmins for ${userData.displayName} was updated`);
            setUidsUserAdmins(uidsData)
            updateUserDataProperty({property: "uidsUserAdmins", value: uidsUserAdmins})
        }
   
        let profileDataRef = Fire.listenToRoute(`publicUserData/${userData.uid}/uidsUserAdmins`, onUidsUserAdminsChange)

        return () =>{
            Fire.off(profileDataRef, "value", onUidsUserAdminsChange)
        }
    },[])

    const handleDeleteAccount = ()=>{
        if(deleteError === "") return alert("You haven't typed anything yet!");
        if(deleteError) return alert('You have a typo in "DELETE"');

        setDeletingAccount(true);
        setShowDeleteAccountModal(false);
        //Remember to code to handle having a chat open with a deleted user

        Fire.getUserData(userData.uid)
        .then((data: UserData)=>{

            const {uidsUserFollows, uidsFollowingUser} = data;

            console.log("Deleting pictures");
            //Deleting profile pictures
            removeUserImages(data);

            console.log("Deleting follower data");
            //Removing follower data
            removeUserAsAFollowerFromOtherUsers(uidsUserFollows)
            removeUserFromUsersWhoWereFollowingThem(uidsFollowingUser);

            finishUpUserDeletion();
        })

    }

    const removeUserAsAFollowerFromOtherUsers = (uidsUserFollows: any)=>{
        if(!uidsUserFollows) return;
        for (let uid of Object.keys(uidsUserFollows)){
            //Replace uidsWhoLikedArray, and decrement a follower
            let currentUserUID = userData.uid;
            Fire.deleteDataAtRoute(`publicUserData/${uid}/uidsFollowingUser/${currentUserUID}`);
        }
    }

    const removeUserFromUsersWhoWereFollowingThem = (uidsFollowingUser: any)=>{
        if(!uidsFollowingUser) return;
        for (let uid of Object.keys(uidsFollowingUser)){
            //Replace uidsUserFollows, and decrement from "following count"
            let currentUserUID = userData.uid;
            Fire.deleteDataAtRoute(`publicUserData/${uid}/uidsUserFollows/${currentUserUID}`);
        }
    }

    const removeUserImages = (data: UserData)=>{
        const {profilePictureKey, coverPhotoKey, monthlySpecialsImageKey, pictureKeys} = data;
        if(!pictureKeys) return;
        //for each image thingy
        Object.values(pictureKeys).forEach((key)=>{
            deleteFromStorage(key, "image");
        });
        //@ts-ignore
        deleteFromStorage(profilePictureKey, "image");
        //@ts-ignore
        deleteFromStorage(coverPhotoKey, "image");
        deleteFromStorage(monthlySpecialsImageKey, "image");
    }

    const finishUpUserDeletion = ()=>{
        console.log("Finishing up user deletion");
        let link = `${SERVER_URL}/deleteUser`;
        let uid = userData.uid;
        fetch(link, {
            method: 'POST',
            headers: {
              // Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({uid: uid})
          })
        .then((response)=>{
            postDeletion();
            return true;
        })
        .catch((error)=>{
            alert('An error occured while deleting your account, please contact support - Ty@alwayslocal.net');
            console.log("Error deleting account");
            console.log("User ID: ", userData.uid);
            console.log("Error: ", error);
            console.log("Error: ", JSON.stringify(error));
            catchErrorForSentry(error);
            return false
        })
    }

    const postDeletion = ()=>{
        console.log("Succesfully deleted account");
        Fire.getAuth().signOut().then(function() {
            // Sign-out successful.
            console.log("Sign Out successful");
            setProcessing(false);

            Fire.deleteDataAtRoute(`publicUserData/${userData.uid}`);
            setDeletingAccount(false);

            dispatch(clearUserData())
            navigation.navigate("Entry")
        }).catch(function(error: any) {
            console.log("Error: ", error);
            console.log("Error signing out user JSON: ", JSON.stringify(error)); 
            catchErrorForSentry(error);
            alert("Error signing you out!")
            // Sentry.captureException("Error signing out user: ");
            // An error happened.
        });


    }

    const handleUpdateAppNotifications = async (isOn: boolean)=> {
        if(isOn){
            let pushToken = await registerForPushNotificationsAsync();
            if(!pushToken) return alert("Failed to get push token");
            Fire.updateRoute(`publicUserData/${userData.uid}`, {pushNotificationsOn: true});
            Fire.updateRoute(`publicUserData/${userData.uid}`, {pushToken: pushToken});
            dispatch(updateUserDataProperty({property: 'pushNotificationsOn', value: true}))
            dispatch(updateUserDataProperty({property: 'pushToken', value: pushToken}));
            setPushNotificationsOn(true);
        }
        else{
            Fire.updateRoute(`publicUserData/${userData.uid}`, {pushNotificationsOn: false});
            Fire.updateRoute(`publicUserData/${userData.uid}`, {pushToken: false});
            dispatch(updateUserDataProperty({property: 'pushNotificationsOn', value: false}));
            dispatch(updateUserDataProperty({property: 'pushToken', value: false}))
            setPushNotificationsOn(false);
        }
        setUpdatingAppNotifications(false);
    }

    const renderReceiveAppNotificationsOption = ()=> {
        if(updatingAppNotifications){
            return <ActivityIndicator size="large" color={COLORS.primaryPurple} />
        }
        else{
            return(
                <View style={{
                    width: '100%',
                    alignSelf: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    // marginVertical: 15,
                    backgroundColor: COLORS.slateGray,
                    padding: 15,
                    // borderRadius: 10,
                    borderBottomWidth: 1,
                    borderColor: "#fff",
                    shadowColor: 'white',
                    shadowOpacity: 0.10,
                    shadowOffset: { width: 0, height: 2 },
                    shadowRadius: 8,
                    elevation: 5,
                    }}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                            <Text style={{color: '#fff', fontSize: scaleFontSize(15)}}>
                                App Notifications
                            </Text>
                            <ToggleSwitch
                                //@ts-ignore
                                isOn={pushNotificationsOn}
                                onColor="#00D768"
                                offColor={COLORS.primaryRed}
                                size="small"
                                onToggle={(isOn: boolean) => {
                                    setUpdatingAppNotifications(true);
                                    console.log("isOn? ", isOn)
                                    handleUpdateAppNotifications(isOn);
                                }}
                            />
                        </View>
                </View>
            )
        }
    }

    // const renderChangePasswordModal = ()=>{
    //     return(
    //         <CustomModal
    //             showSubmit={true}
    //             visibilityControlFunc={setShowDeleteAccountModal}
    //             visibilityFlag={showDeleteAccountModal}
    //             onSubmit={handleDeleteAccount}
    //             title={'Change Password'}
    //         >
    //             <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
    //                 <Text style={{fontSize: scaleFontSize(15), width: '90%', alignSelf: 'center', textAlign: 'center'}}>
    //                     {'Type "DELETE" and press submit to permanently delete your account'}
    //                 </Text>
    //                 <ValidateInput
    //                     placeholder={"New Password"}
    //                     autoFocus={true}
    //                     regex={new RegExp(`DELETE`)}
    //                     // regex={/DELETE/g}
    //                     flagError={(value: any)=>setDeleteError(value)}
    //                     errorMessage={'Looks like you have a typo'}
    //                     style={{
    //                         // ...styles.bioContainer,
    //                         backgroundColor: '#fff',
    //                         borderRadius: 10,
    //                         width: '90%',
    //                         shadowColor: 'black',
    //                         shadowOpacity: 0.10,
    //                         shadowOffset: { width: 0, height: 2 },
    //                         shadowRadius: 8,
    //                         elevation: 5,
    //                         marginTop: 10,
    //                     }}
    //                     onInputChange={(value: any)=>{
    //                         setConfirmDeleteValue(value)
    //                     }}
    //                     defaultValue={confirmDeleteValue}
    //                 />
    //                 <ValidateInput
    //                     placeholder={"DELETE"}
    //                     autoFocus={true}
    //                     regex={new RegExp(`DELETE`)}
    //                     // regex={/DELETE/g}
    //                     flagError={(value: any)=>setDeleteError(value)}
    //                     errorMessage={'Looks like you have a typo'}
    //                     style={{
    //                         // ...styles.bioContainer,
    //                         backgroundColor: '#fff',
    //                         borderRadius: 10,
    //                         width: '90%',
    //                         shadowColor: 'black',
    //                         shadowOpacity: 0.10,
    //                         shadowOffset: { width: 0, height: 2 },
    //                         shadowRadius: 8,
    //                         elevation: 5,
    //                         marginTop: 10,
    //                     }}
    //                     onInputChange={(value: any)=>{
    //                         setConfirmDeleteValue(value)
    //                     }}
    //                     defaultValue={confirmDeleteValue}
    //                 />
    //             </View>
    //         </CustomModal>
    //     )
    // }

    const updateEmail = async ()=>{
        const result = await confirmDecision("Changing Email", 'Are you sure you would like to change your email?');
        if(!result) return;
        Fire.updateEmail(newEmail)
        .then((response)=>{
            showToast("Succesfully updated your email", true);
            alert("Succesfully updated your email!");
            setShowUpdateEmailModal(false);
            Fire.updateUserDataRoute(`${userData.uid}`, { email: newEmail });
            updateUserDataProperty({ property: "email", value: newEmail })
        })
        .catch((error)=>{
            console.log("Failed to update your email");
            alert("Failed to update your email \n Please try again");
            showToast("Failed to change your email", false)
        })
    }
    const UpdateEmailButton = ()=>{
        return(
            <TouchableContainer
                onPress={()=>setShowUpdateEmailModal(true)}
                style={{
                    ...styles.btnStyle,
                    borderBottomWidth: 0,
                    backgroundColor: COLORS.primaryBlue,
                    width: '100%',
                    padding: 10,
                    alignSelf: 'center',
                
                }}
            >
                <Text style={{fontSize: scaleFontSize(13.5), fontWeight: '500', color: 'white', textAlign: 'center', textAlignVertical: 'center', alignSelf: 'center'}}>
                    Change Account Email
                </Text>
            </TouchableContainer>
        )
    }

    const renderUpdateEmailModal = ()=>{
        return(
            <CustomModal
                showSubmit={true}
                visibilityControlFunc={setShowUpdateEmailModal}
                visibilityFlag={showUpdateEmailModal}
                onSubmit={updateEmail}
                title={'Update Email'}
            >
                <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{fontSize: scaleFontSize(15), width: '90%', alignSelf: 'center', textAlign: 'center'}}>
                        {'Please enter your desired new email'}
                    </Text>
                    <ValidateInput
                        placeholder={"Enter new email"}
                        autoFocus={true}
                        regex={regexPatterns.email}
                        // regex={/DELETE/g}
                        flagError={(value: any)=>setUpdateEmailError(value)}
                        errorMessage={errorMessages.emailError}
                        style={{
                            // ...styles.bioContainer,
                            backgroundColor: '#fff',
                            borderRadius: 10,
                            width: '90%',
                            shadowColor: 'black',
                            shadowOpacity: 0.10,
                            shadowOffset: { width: 0, height: 2 },
                            shadowRadius: 8,
                            elevation: 5,
                            marginTop: 10,
                        }}
                        onInputChange={(value: any)=>{
                            setNewEmail(value)
                        }}
                        defaultValue={confirmDeleteValue}
                    />
                </View>
            </CustomModal>
        )
    }
    const renderDeleteAccountModal = ()=>{
        return(
            <CustomModal
                showSubmit={true}
                visibilityControlFunc={setShowDeleteAccountModal}
                visibilityFlag={showDeleteAccountModal}
                onSubmit={handleDeleteAccount}
                title={'Delete Account'}
            >
                <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{fontSize: scaleFontSize(15), width: '90%', alignSelf: 'center', textAlign: 'center'}}>
                        {'Type "DELETE" and press submit to permanently delete your account'}
                    </Text>
                    <ValidateInput
                        placeholder={"DELETE"}
                        autoFocus={true}
                        regex={new RegExp(`DELETE`)}
                        // regex={/DELETE/g}
                        flagError={(value: any)=>setDeleteError(value)}
                        errorMessage={'Looks like you have a typo'}
                        style={{
                            // ...styles.bioContainer,
                            backgroundColor: '#fff',
                            borderRadius: 10,
                            width: '90%',
                            shadowColor: 'black',
                            shadowOpacity: 0.10,
                            shadowOffset: { width: 0, height: 2 },
                            shadowRadius: 8,
                            elevation: 5,
                            marginTop: 10,
                        }}
                        onInputChange={(value: any)=>{
                            setConfirmDeleteValue(value)
                        }}
                        defaultValue={confirmDeleteValue}
                    />
                </View>
            </CustomModal>
        )
    }

    if(deletingAccount){
        return(
            <DrawerContentScrollView contentContainerStyle={{flex: 1, backgroundColor: COLORS.slateGray, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={styles.deletingAccountText}>
                    {'Sad to see you go! 🥺'}
                </Text>
                <Text style={styles.deletingAccountText}>
                    {'But, thank you for your time!😁🙏'}
                </Text>
                <ActivityIndicator size={'large'} color={COLORS.primaryRed}/>
            </DrawerContentScrollView>
        )
    }
    // console.log("Switch data: ", switchData);
    // console.log("userdata.uidsUserAdmins: ", userData.uidsUserAdmins)
    return (
        <DrawerContentScrollView
            {...props}
            contentContainerStyle={{
                flex: 1,
                height: SCREEN_SIZE.height,
                // borderColor: 'red', borderWidth: 1,
                backgroundColor: COLORS.slateGray,
            }}
        >
        {userData.uid && renderUpdateEmailModal()}
        {userData.uid && renderDeleteAccountModal()}
        {userData.uid && <TouchableContainer
            style={styles.btnStyle}
            onPress={()=>{
                navigation.closeDrawer();
                navigation.navigate('UserSearchScreen');
            }}>

                    <View style={{maxWidth: 170, flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Ionicons name={'search'} size={30} color={'#fff'} /> 
                            <Text style={{fontSize: scaleFontSize(15), fontWeight: '500', color: 'white', textAlign: 'center', textAlignVertical: 'center', alignSelf: 'center'}}>
                                Search Users
                            </Text>
                    </View>
        </TouchableContainer>}
        {userData.uid && renderReceiveAppNotificationsOption()}
        {
            (
                (switchData.original.isAppAdmin || userData.isAppAdmin) ||
                (  (switchData.remote.uid === "") && !isObjEmpty(userData.uidsUserAdmins) ) ||
                ( (switchData.original.uid !== "") && switchData.original.hasOwnProperty('uidsUserAdmins') )
            )  &&
        <TouchableContainer
                style={styles.btnStyle}
                onPress={()=>{
                    navigation.navigate('ProfileSelect');
                }}
        >
            <Text style={{fontSize: scaleFontSize(18), fontWeight: '500', color: 'white', textAlign: 'center', textAlignVertical: 'center', alignSelf: 'center'}}>
                Switch Accounts
            </Text>
        </TouchableContainer>
        }
        <TouchableContainer
                style={styles.btnStyle}
                onPress={()=>{
                    navigation.navigate('InviteContacts');
                }}
        >
            <Text style={{fontSize: scaleFontSize(18), fontWeight: '500', color: 'white', textAlign: 'center', textAlignVertical: 'center', alignSelf: 'center'}}>
                Invite Contacts
            </Text>
        </TouchableContainer>
        
        <TouchableContainer
            style={styles.btnStyle}
            onPress={()=>{
                // console.log("Logging in");
                if(!userData.uid) return navigation.navigate("Entry");
                setProcessing(true);
                Fire.getAuth().signOut().then(function() {
                    // Sign-out successful.
                    console.log("Sign Out successful");
                    setProcessing(false);
                    dispatch(clearUserData());
                    navigation.closeDrawer();
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: 'Entry' }], // this name should be your initial route
                        }),
                        );
                }).catch(function(error: any) {
                    console.log("Error: ", error);
                    console.log("Error signing out user JSON: ", JSON.stringify(error)); 
                    catchErrorForSentry(error);
                    alert("Error signing you out!")
                    // Sentry.captureException("Error signing out user: ");
                    // An error happened.
                });
            }}
        >
            <Text style={{fontSize: userData.uid ? scaleFontSize(20) : scaleFontSize(13.5), fontWeight: '500', color: 'white', textAlign: 'center', textAlignVertical: 'center', alignSelf: 'center'}}>
                {userData.uid ? 'Log out' : 'Back To Sign Up'}
            </Text>
        </TouchableContainer>

        {userData.uid &&
        <View style={{
            position: 'absolute',
            bottom: 40,
            width: '100%',
        }}>
            <UpdateEmailButton/>
            <TouchableContainer
                onPress={async ()=>{
                    const result = await confirmDecision("Resetting password", 'Are you sure you would like to reset your password?');
                    if(!result) return;
                    Fire.sendPasswordResetEmail(userData.email)
                    .then(()=>{
                        showResetPasswordToast({
                            type: 'resetPassword',
                            position: 'bottom',
                            text1: `${userData.email}`,
                            autoHide: false,
                            // visibilityTime: 2000,
                        });

                    })
                    .catch((error)=>{
                        showToast("Failed to send password reset email", false)
                    })
                }}
                style={{
                    ...styles.btnStyle,
                    borderBottomWidth: 0,
                    backgroundColor: COLORS.primaryBlue,
                    width: '100%',
                    padding: 10,
                    alignSelf: 'center',
                
                }}
            >
                <Text style={{fontSize: scaleFontSize(13.5), fontWeight: '500', color: 'white', textAlign: 'center', textAlignVertical: 'center', alignSelf: 'center'}}>
                    Reset Password
                </Text>
            </TouchableContainer>
            <TouchableContainer
                onPress={()=>{
                    setShowDeleteAccountModal(true);
                }}
                style={{
                    ...styles.btnStyle,
                    borderBottomWidth: 0,
                    backgroundColor: COLORS.primaryRed,
                    width: '100%',
                    padding: 10,
                    alignSelf: 'center',
                    // position: 'absolute',
                    // bottom: 0,
                }}
            >
                <Text style={{fontSize: scaleFontSize(13.5), fontWeight: '500', color: 'white', textAlign: 'center', textAlignVertical: 'center', alignSelf: 'center'}}>
                    Delete Account
                </Text>
            </TouchableContainer>
        </View>
        
        }

        </DrawerContentScrollView>
    );
};

const styles = StyleSheet.create({
    container:{
        flex: 1,
        // borderColor: 'red',
        // borderWidth: 1,
        alignContent: 'center',
        justifyContent: 'center',
    },
    ctaBtnStyle: {
        borderRadius: 5,
        // backgroundColor: '#DD222E',
        backgroundColor: COLORS.primaryBlue,
        width: '85%',
        height: 50,
        justifyContent: 'center',
    },
    deletingAccountText: {
        color: 'white',
        width: '75%',
        fontSize: scaleFontSize(15),
        textAlign: 'center',
        margin: 5
    },
    btnStyle: {
        backgroundColor: COLORS.slateGray,
        shadowColor: 'white',
        shadowOpacity: 0.10,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 8,
        elevation: 5,
        borderBottomWidth: 1,
        borderColor: 'white',
        alignItems: 'center',
        padding: 10,
    }
})

export default HamburgerMenu