import { Platform, Linking } from 'react-native';
import ensureProtocol from './ensureProtocol';

function openAppStore() {
  const iosStoreUrl = 'https://apps.apple.com/us/app/always-local/id6464148766';
  const androidStoreUrl = 'https://play.google.com/store/apps/details?id=com.tyfoods.alwayslocal&hl=en_US';

  let url;

  if (Platform.OS === 'ios') {
    // iOS native app
    url = iosStoreUrl;
  } else if (Platform.OS === 'android') {
    // Android native app
    url = androidStoreUrl;
  } else if (Platform.OS === 'web') {
    // Web environment
    //@ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
    //@ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // iOS web
      url = iosStoreUrl;
    } else if (/Macintosh/.test(userAgent)) {
      // macOS web
      url = iosStoreUrl;
    } else {
      // Default to Android store for other platforms
      url = androidStoreUrl;
    }
  } else {
    // Default to Android store for unknown platforms
    url = androidStoreUrl;
  }

  // Ensure the URL has the correct protocol
  const validUrl = ensureProtocol(url);

  // Open the URL
  Linking.openURL(validUrl).catch(err => console.error('An error occurred', err));
}

export default openAppStore;



// import { Platform, Linking } from 'react-native';
// import ensureProtocol from './ensureProtocol';

// function openAppStore() {
//   const iosStoreUrl = 'https://apps.apple.com/us/app/always-local/id6464148766';
//   const androidStoreUrl = 'https://play.google.com/store/apps/details?id=com.tyfoods.alwayslocal&hl=en_US';

//   let url;

//   if (Platform.OS === 'ios') {
//     // iOS native app
//     url = iosStoreUrl;
//   } else if (Platform.OS === 'android') {
//     // Android native app
//     url = androidStoreUrl;
//   } else if (Platform.OS === 'web') {
//     // Web environment
//     const userAgent = navigator.userAgent || (navigator as any).vendor || (window as any).opera;

//     if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
//       // iOS web
//       url = iosStoreUrl;
//     } else if (/android/i.test(userAgent)) {
//       // Android web
//       url = androidStoreUrl;
//     } else if (/Macintosh/.test(userAgent)) {
//       // macOS web
//       url = iosStoreUrl; // Assuming you want to direct macOS users to the iOS App Store
//     } else {
//       // Default to Android store for other platforms
//       url = androidStoreUrl;
//     }
//   } else {
//     // Default to Android store for unknown platforms
//     url = androidStoreUrl;
//   }

//   // Ensure the URL has the correct protocol
//   const validUrl = ensureProtocol(url);

//   // Open the URL
//   Linking.openURL(validUrl).catch(err => console.error('An error occurred', err));
// }

// export default openAppStore;