import { useSelector } from "react-redux";
import Card from "./Card";
import GigDisplayer from "./GigDisplayer";
import { RootState } from "../store";
import scaleFontSize from "../helpers/scaleFontSize";
import { Text } from "react-native";
import { fakeGigData } from "../Constants";
import dayjs from 'dayjs';




const FoodTruckSchedule = (props: any) => {
    const userData = useSelector((state: RootState) => state.userData);
    const { profileData, navigation, foodSchedule } = props;
    const isProfileOwner = userData.uid === profileData.uid;


    const haveAllFoodTruckGigsPassed = () => {
        if (!foodSchedule || foodSchedule.length === 0) {
            return true;
        }
        const currentDate = dayjs().startOf('day');
        return foodSchedule.every((gig: any) => {
            const gigDate = dayjs(gig.date).startOf('day');
            return gigDate.isBefore(currentDate);
        });
    };

    const allGigsPassed = haveAllFoodTruckGigsPassed();
    const emptyCondition = (allGigsPassed) || ((!foodSchedule || foodSchedule.length === 0));
    // console.log("Is schedule empty: ", emptyCondition);
    // console.log("First part: ", (isProfileOwner && allGigsPassed))
    // console.log("Second part: ", (isProfileOwner && (!foodSchedule || foodSchedule.length === 0)))
    return (
        <Card style={{flex:1, alignItems: 'center', justifyContent: 'center', padding:5 }}>
            { emptyCondition && (
                <Text style={{ fontSize: scaleFontSize(12), textAlign: 'center', fontWeight: '500' }}>
                    {isProfileOwner ?
                    'This will be populated with your food gigs once your requests are accepted!' :
                    `This will be populated with dates and times once ${profileData.displayName} has added them!`
                    }
                    {/* 'No food truck stops scheduled yet'} */}
                </Text>
            )}
            { emptyCondition && (
                <>
                    <Text style={{ fontSize: scaleFontSize(10), textAlign: 'center'}}>
                        Here are some examples
                    </Text>
                    <GigDisplayer push={navigation.push} onAccount={true} gigs={fakeGigData} />
                </>
            )}
            {foodSchedule && foodSchedule.length > 0 && !allGigsPassed && (
                <GigDisplayer style={{maxHeight: undefined}} push={navigation.push} onAccount={true} gigs={foodSchedule}/>
            )}
            {/* {allGigsPassed && !isProfileOwner && (
                <Text style={{ fontSize: scaleFontSize(12), textAlign: 'center', fontWeight: '500' }}>
                    No food truck stops scheduled yet
                </Text>
            )} */}
        </Card>
    );
};

export default FoodTruckSchedule