import * as React from 'react';
import {
  Alert,
  Text,
  View,
  StyleSheet,
  SectionList,
  TouchableHighlight,
  Button,
} from 'react-native';
import Constants from 'expo-constants';
import { composeAsync } from 'expo-mail-composer';
import { isAvailableAsync, sendSMSAsync } from 'expo-sms';
// import {
//   getContactsAsync,
//   Contact,
//   EMAILS,
//   PHONE_NUMBERS,
// } from 'expo-contacts';
import * as Contacts from 'expo-contacts';
// @ts-ignore
import { groupBy } from 'lodash';
import SearchBar from '../components/SearchBar';
// import i18n from '../i18n';


//Courtesy: https://blog.expo.io/help-your-users-share-their-love-for-your-app-with-react-native-7549b1b0b3af

// Is this redux?
type ContactsState = {
  loading: boolean;
  error: any | null;
  data: Array<any>;
};

function useContacts() {
  const [contacts, setContacts] = React.useState<ContactsState>({
    loading: true,
    error: null,
    data: [],
  });
  React.useEffect(() => {
    // Fetch contacts using the expo-contacts module
    (async () => {
      const { status } = await Contacts.requestPermissionsAsync();
      if (status === 'granted') {
        const { data } = await Contacts.getContactsAsync({
          fields: [Contacts.Fields.Emails, Contacts.Fields.PhoneNumbers],
        });

        if (data.length > 0) {
          // You can process the contacts data here
          console.log('First contact:', data[0]);
        }
        setContacts({
          loading: false,
          error: null,
          data: data,
        });
      } else {
        // Handle permissions denied
        setContacts({
          loading: false,
          error: {message: 'Permission denied'},
          data: [],
        });
      }
    })();
  }, []);

  return contacts;
}

type RowItem = {
    id: string;
    name: string;
    phoneNumber?: string;
    email?: string;
};

type ContactRowProps = {
  name: string;
  emailOrNumber: string;
  onPress: () => void;
  selected: boolean;
};

const ContactRow = React.memo(
  ({ onPress, name, emailOrNumber, selected }: ContactRowProps) => {
    return (
      <TouchableHighlight onPress={onPress}>
        <View
          key={emailOrNumber}
          style={{ padding: 16, flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ marginRight: 16 }}>{selected ? '✅' : '⭕️'}</Text>
          <View style={{ flex: 1 }}>
            <Text>{name || emailOrNumber}</Text>
            {name.length > 0 && (
              <Text style={{ marginTop: 4, color: '#666' }}>
                {emailOrNumber}
              </Text>
            )}
          </View>
        </View>
      </TouchableHighlight>
    );
  }
);


function InviteScreen() {
  const contacts = useContacts();
  const originalSections = React.useMemo(() => {
    // @ts-ignore
    return Object.entries(
      groupBy(
        // Create one contact per phone number and email.
        contacts.data.reduce(
          (res: any, cur: any) => {
            if (cur.phoneNumbers != null) {
              for (const p of cur.phoneNumbers) {
                res.push({
                  id: cur.id + p.number + Math.random(),
                  name: cur.name || '',
                  phoneNumber: p.number,
                });
              }
            }
            if (cur.emails != null) {
              for (const e of cur.emails) {
                res.push({
                  id: cur.id + e.email + Math.random(),
                  name: cur.name || '',
                  email: e.email,
                });
              }
            }
            return res;
          },
          [] as Array<RowItem>
        ),
        (c: RowItem) => {
          const firstChar = (c.name.charAt(0) || '#').toLowerCase();
          return firstChar.match(/[a-z]/) ? firstChar : '#';
        }
      )
    )
      .map(([key, value]: any) => ({
        key,
        data: value.sort((a: any, b: any) =>
          (a.name || a.name || '') < (b.name || b.name || '') ? -1 : 1
        ),
      }))
      .sort((a: { key: string }, b: { key: string }) =>
        a.key < b.key ? -1 : 1
      );

  }, [contacts.data]);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [sections, setSections] = React.useState<any>(originalSections);
  const [selectedContacts, setSelectedContacts] = React.useState<RowItem[]>([]);

//   React.useEffect(()=>{
//     console.log("Sections have been modified");
//   }, [sections])

  
  const returnSections = ()=>{
      if(sections.length && (searchQuery !== "")){
        //   console.log(sections);
        //   console.log("Returning new sections");
        return sections;
      }
      else{
        //   console.log(originalSections)
        //   console.log("Returning original sections");
          return originalSections
      }
  }

  const setSectionsAccordingToSearchQuery = (value: any)=>{
    if(!value) return setSections(originalSections);
    //Grab appropriate section
    let section;
    for ( let obj of originalSections ){
        if(obj.key === value[0].toLowerCase()){
            section = {...obj};
        }
    }

    //Process the section and then set it as new section
    if(value.length > 1){
        let newSection = {
            key: value[0].toLowerCase(),
            data: []
        };
        newSection.data = section?.data.filter((data: any) => {
            return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        })
        setSections([newSection]);
    }
    else{
        setSections([section]);
    }
  }
  
  const onInvitePress = async () => {
    let didShare = false;
    const message = `Come join the AlwaysLocal app! - https://alwayslocal.net/signup`;
    const emails = selectedContacts
      .filter(c => c.email != null)
      .map(c => c.email) as string[];
    const phoneNumbers = selectedContacts
      .filter(c => c.phoneNumber != null)
      .map(c => c.phoneNumber) as string[];
    if (emails.length > 0) {
      try {
        const result = await composeAsync({
          recipients: emails,
          subject: 'Welcome to AlwaysLocal App!',
          body: message,
          isHtml: false,
        });
        didShare = didShare || result.status === 'sent';
        thankUserForSharing(didShare);
      } catch (ex: any) {
        Alert.alert(ex.message);
      }
    }
    if (phoneNumbers.length > 0 && (await isAvailableAsync())) {
      try {
        const result = await sendSMSAsync(phoneNumbers, message);
        console.log("Result: ", result.result);
        didShare = didShare || result.result === 'sent';
        thankUserForSharing(didShare);
      } catch (ex: any) {
        Alert.alert(ex.message);
      }
    }
  };

  //This function executes before the user can even take action.
  //Not sure why, seems like sendSMSAsync just doesn't work properly. -shrug-
  //Might have to do with the fact I have Messenger as my SMS app.

  //Works buttery smooth on the iPhone!
  const thankUserForSharing = (didShare: boolean)=>{
    if (didShare) {
        // Alert.alert(i18n.t('ThankYouForSharing'));
        Alert.alert("Thank you for sharing")
    }
  }

  if (contacts.loading) {
    return <Text>Loading...</Text>;
  } else if (contacts.error != null) {
    return <Text>Error getting your contacts 🥺 - Please screenshot this error message and contact Ty@TyfoodsForThought.com {contacts.error.message}</Text>;
  } else {
    // console.log("Sections: ", sections);
    return (
      <>
        <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setData={setSectionsAccordingToSearchQuery}
        />
        <SectionList
          key={searchQuery}
          sections={returnSections()}
          renderSectionHeader={({ section }) => (
            <Text
              style={{
                backgroundColor: '#eee',
                paddingHorizontal: 16,
                paddingVertical: 4,
              }}>
              {section.key!.toUpperCase()}
            </Text>
          )}
          renderItem={({ item }: { item: RowItem }) => {
            const selectedIndex = selectedContacts.findIndex(
              i => i.id === item.id
            );
            const onPress = () => {
              const newContacts = [...selectedContacts];
              if (selectedIndex >= 0) {
                newContacts.splice(selectedIndex, 1);
              } else {
                newContacts.push(item);
              }
              setSelectedContacts(newContacts);
            };
            return (
              <ContactRow
                name={item.name}
                emailOrNumber={(item.email || item.phoneNumber)!}
                selected={selectedIndex >= 0}
                onPress={onPress}
              />
            );
          }}
          extraData={selectedContacts}
          contentContainerStyle={{ paddingBottom: 104 }}
        />

        <View style={{ borderTopColor: '#ccc', borderTopWidth: 1, padding: 8 }}>
          <Button
            // title={`${i18n.t('Invite')} (${selectedContacts.length})`}
            title={`Invite (${selectedContacts.length})`}
            onPress={onInvitePress}
            disabled={selectedContacts.length === 0}
          />
        </View>

      </>
    );
  }
}

export default InviteScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: Constants.statusBarHeight,
    backgroundColor: '#ecf0f1',
    padding: 8,
  },
  paragraph: {
    margin: 24,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
