import {View, Text, StyleSheet, ActivityIndicator} from 'react-native';
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';
import { COLORS, EDGES, SCREEN_SIZE, SERVER_URL, isDeviceOld } from '../Constants';
import { useEffect, useState } from 'react';
import CustomButton from '../components/CustomButton';
import Fire from '../Fire';
import catchErrorForSentry from '../helpers/catchSentryError';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserData, setUserData, updateUserDataProperty } from '../store/slices/userData';
import { Screen } from '../components/Screen';
import ToggleSwitch from 'toggle-switch-react-native';
import { RootState } from '../store';
import registerForPushNotificationsAsync from '../helpers/registerPushNotificationsAsync';
import TouchableContainer from '../components/TouchableContainer';
import { Ionicons } from '@expo/vector-icons';
import CustomModal from '../components/CustomModal';
import ValidateInput from '../components/ValidateInput';
import { UserData } from '../Interfaces';
import deleteFromStorage from '../helpers/deleteFromStorage';
import scaleFontSize from '../helpers/scaleFontSize';
import { CommonActions } from '@react-navigation/native';
import { clearSwitchData } from '../store/slices/switchData';



const MenuScreen = (props: any)=>{
    const { navigation }  = props;
    const dispatch = useDispatch();
    const [processing, setProcessing] = useState(false);
    const userData = useSelector((state: RootState) => state.userData);
    const [updatingAppNotifications, setUpdatingAppNotifications] = useState(false);
    const [sendAppNotifications, setSendAppNotifications] = useState(userData?.pushToken && userData.pushNotificationsOn);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    const [confirmDeleteValue, setConfirmDeleteValue] = useState("");
    const [deletingAccount, setDeletingAccount] = useState(false);
    const [deleteError, setDeleteError] = useState("");
    const [uidsUserAdmins, setUidsUserAdmins] = useState(); 


    useEffect(()=>{
        console.log("Menu screen rendered");
        const onUidsUserAdminsChange = (userData: any)=>{
            if(!userData) return;
            console.log(`uidsUserAdmins for ${userData.displayName} was updated`);
            setUidsUserAdmins(userData)
            updateUserDataProperty({property: "uidsUserAdmins", value: uidsUserAdmins})
        }
   
        let profileDataRef = Fire.listenToRoute(`publicUserData/${userData.uid}/uidsUserAdmins`, onUidsUserAdminsChange)

        return () =>{
            Fire.off(profileDataRef, "value", onUidsUserAdminsChange)
        }
    },[])

    const handleDeleteAccount = ()=>{
        if(deleteError === "") return alert("You haven't typed anything yet!");
        if(deleteError) return alert('You have a typo in "DELETE"');

        setDeletingAccount(true);
        setShowDeleteAccountModal(false);
        //Remember to code to handle having a chat open with a deleted user

        Fire.getUserData(userData.uid)
        .then((data: UserData)=>{

            const {uidsUserFollows, uidsFollowingUser} = data;

            console.log("Deleting pictures");
            //Deleting profile pictures
            removeUserImages(data);
            console.log("Deleting follower data");
            //Removing follower data
            removeUserAsAFollowerFromOtherUsers(uidsUserFollows)
            removeUserFromUsersWhoWereFollowingThem(uidsFollowingUser);
            //Remove Employ Data
            removeEmploymentData(data);
            //Remove Related Gig Data
            removeGigData(data);
            //remove account user admins
            removeUidsUserAdmins(data);

            finishUpUserDeletion();
        })

    }

    const removeGigData = (data: UserData)=>{
        if(!data.entertainmentSchedule && !data.foodSchedule) return;
        const foodGigs = data.foodSchedule ? Object.values(data.foodSchedule) : [];
        const entertainmentGigs = data.entertainmentSchedule ? Object.values(data.entertainmentSchedule) : [];
        const gigs = [...foodGigs, ...entertainmentGigs];
        for (let gig of gigs){
            //If gig is is with an employer, then delete it from that employer.
            if(!gig.location){
                const employerUid = gig.employer;
                Fire.deleteDataAtRoute(`publicUserData/${employerUid}/gigs/${gig.id}`);
            }
        }
    }

    const removeUidsUserAdmins = (data: UserData)=>{
        if(!data.uidsUserAdmins) return;
        const uidsUserAdmins = Object.keys(data.uidsUserAdmins);
        let currentUserUID = userData.uid;
        for (let uid of uidsUserAdmins){
            Fire.deleteDataAtRoute(`publicUserData/${uid}/admins/${currentUserUID}`);
        }
    }

    const removeEmploymentData = (data: UserData)=>{
        if(!data.employers && !data.employees) return;
        const employerUids = data.employers ? Object.keys(data.employers) : [];
        const employeeUids = data.employees ? Object.keys(data.employees) : [];
        const employmentUids = [...employerUids, ...employeeUids];
        let currentUserUID = userData.uid;
        for (let uid of employmentUids){
            if(employeeUids.includes(uid)){
                Fire.deleteDataAtRoute(`publicUserData/${uid}/employers/${currentUserUID}`);
            }
            if(employerUids.includes(uid)){
                Fire.deleteDataAtRoute(`publicUserData/${uid}/employees/${currentUserUID}`);
            }
        }
    }

    const removeUserAsAFollowerFromOtherUsers = (uidsUserFollows: any)=>{
        if(!uidsUserFollows) return;
        for (let uid of Object.keys(uidsUserFollows)){
            //Replace uidsWhoLikedArray, and decrement a follower
            let currentUserUID = userData.uid;
            Fire.deleteDataAtRoute(`publicUserData/${uid}/uidsFollowingUser/${currentUserUID}`);
        }
    }

    const removeUserFromUsersWhoWereFollowingThem = (uidsFollowingUser: any)=>{
        if(!uidsFollowingUser) return;
        for (let uid of Object.keys(uidsFollowingUser)){
            //Replace uidsUserFollows, and decrement from "following count"
            let currentUserUID = userData.uid;
            Fire.deleteDataAtRoute(`publicUserData/${uid}/uidsUserFollows/${currentUserUID}`);
        }
    }

    const removeUserImages = (data: UserData)=>{
        const {profilePictureKey, coverPhotoKey, monthlySpecialsImageKey, pictureKeys} = data;
        if(!pictureKeys) return;
        //for each image thingy
        Object.values(pictureKeys).forEach((key)=>{
            deleteFromStorage(key, "image");
        });
        //@ts-ignore
        deleteFromStorage(profilePictureKey, "image");
        //@ts-ignore
        deleteFromStorage(coverPhotoKey, "image");
        deleteFromStorage(monthlySpecialsImageKey, "image");
    }

    const finishUpUserDeletion = ()=>{
        console.log("Finishing up user deletion");
        let link = `${SERVER_URL}/deleteUser`;
        let uid = userData.uid;
        fetch(link, {
            method: 'POST',
            headers: {
              // Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({uid: uid})
          })
        .then((response)=>{
            postDeletion();
            return true;
        })
        .catch((error)=>{
            alert('An error occured while deleting your account, please contact support - Ty@alwayslocal.net');
            console.log("Error deleting account");
            console.log("User ID: ", userData.uid);
            console.log("Error: ", error);
            console.log("Error: ", JSON.stringify(error));
            catchErrorForSentry(error);
            return false
        })
    }

    const postDeletion = ()=>{
        console.log("Succesfully deleted account");
        Fire.getAuth().signOut().then(function() {
            // Sign-out successful.
            console.log("Sign Out successful");
            setProcessing(false);

            Fire.deleteDataAtRoute(`publicUserData/${userData.uid}`);
            Fire.deleteDataAtRoute(`phoneNumbers/${userData.uid}`);

            dispatch(clearSwitchData())
            dispatch(clearUserData())
            navigation.navigate("Entry")
        }).catch(function(error: any) {
            console.log("Error: ", error);
            console.log("Error signing out user JSON: ", JSON.stringify(error)); 
            catchErrorForSentry(error);
            alert("Error signing you out!")
            // Sentry.captureException("Error signing out user: ");
            // An error happened.
        });


    }

    const handleUpdateAppNotifications = async (isOn: boolean)=> {

        if(isOn){
            console.log()
            let pushToken = await registerForPushNotificationsAsync();
            Fire.setUserDataRoute(`publicUserData/${userData.uid}/pushNotificationsOn`, true);
            Fire.setUserDataRoute(`publicUserData/${userData.uid}/pushToken`, pushToken);
            updateUserDataProperty({property: 'pushNotificationsOn', value: true})
            updateUserDataProperty({property: 'pushToken', value: pushToken})
            setSendAppNotifications(true);
        }
        else{
            Fire.setUserDataRoute(`publicUserData/${userData.uid}/pushNotificationsOn`, false);
            Fire.setUserDataRoute(`publicUserData/${userData.uid}/pushToken`, null);
            updateUserDataProperty({property: 'pushNotificationsOn', value: false});
            updateUserDataProperty({property: 'pushToken', value: null})
            setSendAppNotifications(false);
        }
   
        setUpdatingAppNotifications(false);
    }

    const renderReceiveAppNotificationsOption = ()=> {
        if(updatingAppNotifications){
            return <ActivityIndicator size="large" color={COLORS.primaryPurple} />
        }
        else{
            return(
                <View style={{
                    width: '85%',
                    alignSelf: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    marginBottom: 15,
                    backgroundColor: 'white',
                    padding: 15,
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: COLORS.primaryBlue,
                    shadowColor: 'black',
                    shadowOpacity: 0.26,
                    shadowOffset: { width: 0, height: 2 },
                    shadowRadius: 8,
                    elevation: 5,
                    }}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                            <Text style={{color: COLORS.primaryBlue, fontSize: scaleFontSize(14)}}>
                                Receive app notifications
                            </Text>
                            <ToggleSwitch
                                isOn={sendAppNotifications}
                                onColor="#00D768"
                                offColor={COLORS.primaryRed}
                                size="small"
                                onToggle={(isOn: boolean) => {
                                    setUpdatingAppNotifications(true);
                                    // console.log("Is On? ", isOn);
                                    handleUpdateAppNotifications(isOn);
                                }}
                            />
                        </View>
                </View>
            )
        }
    }

    const renderDeleteAccountModal = ()=>{
        return(
            <CustomModal
                showSubmit={true}
                visibilityControlFunc={setShowDeleteAccountModal}
                visibilityFlag={showDeleteAccountModal}
                onSubmit={handleDeleteAccount}
                title={'Delete Account'}
            >
                <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{fontSize: scaleFontSize(15), width: '90%', alignSelf: 'center', textAlign: 'center'}}>
                        {'Type "DELETE" and press submit to permanently delete your account'}
                    </Text>
                    <ValidateInput
                        placeholder={"DELETE"}
                        autoFocus={true}
                        regex={new RegExp(`DELETE`)}
                        // regex={/DELETE/g}
                        flagError={(value: any)=>setDeleteError(value)}
                        errorMessage={'Looks like you have a typo'}
                        style={{
                            // ...styles.bioContainer,
                            backgroundColor: '#fff',
                            borderRadius: 10,
                            width: '90%',
                            shadowColor: 'black',
                            shadowOpacity: 0.26,
                            shadowOffset: { width: 0, height: 2 },
                            shadowRadius: 8,
                            elevation: 5,
                            marginTop: 10,
                        }}
                        onInputChange={(value: any)=>{
                            setConfirmDeleteValue(value)
                        }}
                        defaultValue={confirmDeleteValue}
                    />
                </View>
            </CustomModal>
        )
    }

    const { bottom } = useSafeAreaInsets();
    if(deletingAccount){
        return(
            <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={styles.deletingAccountText}>
                    {'Sad to see you go! 🥺'}
                </Text>
                <Text style={styles.deletingAccountText}>
                    {'But, thank you for your time!😁🙏'}
                </Text>
                <ActivityIndicator size={'large'} color={COLORS.primaryRed}/>
            </View>
        )
    }
    return(
        <View style={{flex: 1, backgroundColor: '#fff', paddingBottom: !isDeviceOld ? bottom : 0}}>
            {renderDeleteAccountModal()}
            <View style={styles.container}>
                <View style={{
                    flexDirection: 'column',
                    // borderColor: 'red', borderWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'space-around'
                }}>
                        {userData.uid && renderReceiveAppNotificationsOption()}
                        <TouchableContainer
                            style={{
                                alignSelf: 'center',
                                borderRadius: 5,
                                padding: 10,
                                width: '85%',
                                // maxWidth: 170,
                                backgroundColor: COLORS.primaryBlue,
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                                margin: 5,
                            }}
                            onPress={()=>{
                                navigation.navigate('UserSearchScreen');
                            }}>
  
                                    <View style={{maxWidth: 170, flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <Ionicons name={'search'} size={SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055} color={'#fff'} /> 
                                            <Text style={{fontSize: scaleFontSize(15), fontWeight: '500', color: 'white', textAlign: 'center', textAlignVertical: 'center', alignSelf: 'center'}}>
                                                Search Users
                                            </Text>
                                    </View>
                        </TouchableContainer>
                        
                        <CustomButton
                            style={styles.ctaBtnStyle}
                            textStyle={{
                                fontSize: scaleFontSize(20),
                                // alignSelf: 'center',
                                color: 'white'
                            }}
                            title={"Log out"}
                            onPress={()=>{
                                // console.log("Logging in");
                                if(!userData.uid) return navigation.navigate("Entry");
                                setProcessing(true);
                                Fire.getAuth().signOut().then(function() {
                                    // Sign-out successful.
                                    console.log("Sign Out successful");
                                    setProcessing(false);
                                    dispatch(clearUserData());
                                    navigation.dispatch(
                                        CommonActions.reset({
                                          index: 0,
                                          routes: [{ name: 'Entry' }], // this name should be your initial route
                                        }),
                                      );
                                }).catch(function(error: any) {
                                    console.log("Error: ", error);
                                    console.log("Error signing out user JSON: ", JSON.stringify(error)); 
                                    catchErrorForSentry(error);
                                    alert("Error signing you out!")
                                    // Sentry.captureException("Error signing out user: ");
                                    // An error happened.
                                });
                            }}
                        />

                    </View>
            </View>
            {userData.uid && <CustomButton
                title={'Delete Account'}
                textStyle={{fontSize: scaleFontSize(15), color: '#fff', fontWeight: 'bold'}}
                style={{
                    backgroundColor: COLORS.primaryRed,
                    width: '50%',
                    padding: 10,
                    alignSelf: 'center',
                    position: 'absolute',
                    bottom: 10,
                    borderRadius: 10,
                    // marginBottom: Header
                }}
                onPress={()=>{
                    setShowDeleteAccountModal(true);
                }}
            />}
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
        flex: 1,
        // borderColor: 'red',
        // borderWidth: 1,
        alignContent: 'center',
        justifyContent: 'center',
    },
    ctaBtnStyle: {
        borderRadius: 5,
        // backgroundColor: '#DD222E',
        backgroundColor: COLORS.primaryBlue,
        width: '85%',
        height: 50,
        justifyContent: 'center',
    },
    deletingAccountText: {
        width: '75%',
        fontSize: scaleFontSize(15),
        textAlign: 'center',
        margin: 5
    }
})

export default MenuScreen;