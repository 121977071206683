function removeUnitedStatesFromAddress(address: string) {
    // Define a regular expression to match "USA", "United States", and variations
    const regex = /\b(USA|United States|U\.S\.A\.|U\.S\.|US)\b/gi;
  
    // Replace matched patterns with an empty string
    let cleanedAddress = address.replace(regex, '').trim();
  
    // Remove a trailing comma if it exists
    cleanedAddress = cleanedAddress.replace(/,\s*$/, '');
  
    // Return the cleaned address
    return cleanedAddress;
}

export default removeUnitedStatesFromAddress;