import Navigation from './navigation';
import * as Localization from 'expo-localization';
// import { I18n } from 'i18n-js';
import Strings from './Constants/Strings';
import { Provider } from 'react-redux';
import store from './store';
import * as Sentry from 'sentry-expo';
import React, { useEffect } from 'react';
import { registerRootComponent } from 'expo';
import { Alert, Platform, Text, View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Fire from './Fire';
import isAccountInformationTaken from './helpers/IsAccountInformationTaken';
import * as Updates from 'expo-updates';
import catchErrorForSentry from './helpers/catchSentryError';
import { StatusBar } from 'expo-status-bar';
import Toast, { BaseToast, ErrorToast } from 'react-native-toast-message';
import Card from './components/Card';
import { SigningUpProvider } from './screens/SigningUpContext'; // Import the useSigningUp hook
import 'react-native-get-random-values';

Sentry.init({
  dsn: 'https://99cfaf13e8fb4990a29457baf1064d99@o360186.ingest.sentry.io/4505280069042176',
  enableInExpoDevelopment: false,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});
// const i18n = new I18n(Strings);
// const locale = Localization.locale
// i18n.locale = locale;
// // console.log("i18n: ", i18n);
// i18n.enableFallback = true;

const toastConfig = {
  /*
    Overwrite 'success' type,
    by modifying the existing `BaseToast` component
  */
  success: (props: any) => (
    <BaseToast
      {...props}
      style={{ borderLeftColor: 'lime' }}
      contentContainerStyle={{ paddingHorizontal: 15 }}
      text1Style={{
        fontSize: 15,
        fontWeight: '400'
      }}
    />
  ),
  /*
    Overwrite 'error' type,
    by modifying the existing `ErrorToast` component
  */
  error: (props: any) => (
    <ErrorToast
      {...props}
      text1Style={{
        fontSize: 17
      }}
      text2Style={{
        fontSize: 15
      }}
    />
  ),
  resetPassword: ({ text1 }: any) => (
    <Card style={{
        borderLeftColor: 'lime',
        borderLeftWidth: 10,
        height: 60,
        width: '90%',
        
        justifyContent: 'center',
      }}>
      {/* <Text style={{marginLeft: '5%'}}>Success</Text> */}
      <Text style={{marginLeft: '5%',}}>
        A password reset email has been sent to 
        <Text style={{textDecorationLine: 'underline', textDecorationColor: 'blue', color: 'blue',}}> {text1}</Text>
        
      </Text>
    </Card>
  )
};

registerRootComponent(App);
export default function App() {

    useEffect(() => {
      if (!__DEV__) {  // Check if not in development mode
        onFetchUpdateAsync();
      }
    }, []);

    // async function onFetchUpdateAsync() {
    //   try {
    //     const update = await Updates.checkForUpdateAsync();

    //     if (update.isAvailable) {
    //       await Updates.fetchUpdateAsync();
    //       await Updates.reloadAsync();
    //     }
    //   } catch (error) {
    //     // You can also add an alert() to see the error message in case of an error when fetching updates.
    //     console.log("Failed to get the latest update");
    //     catchErrorForSentry(error);
    //   }
    // }

  
    async function onFetchUpdateAsync() {
      try {
        const update = await Updates.checkForUpdateAsync();
        if (update.isAvailable) {
          await Updates.fetchUpdateAsync();
          await Updates.readLogEntriesAsync();
          // Alert.alert('Update available', 'An update is available. Restart the app to apply the update.', [
          //   { text: 'Restart', onPress: () => Updates.reloadAsync() }
          // ]);
        }
      } catch (error) {
        console.log("Failed to get the latest update");
        catchErrorForSentry(error);
      }
    }

    return Platform.OS === 'web' ?
     (
      <SigningUpProvider>
        <Provider store={store}>
          <Navigation/>
        </Provider>
      </SigningUpProvider>

     )
    :
    (
      <SigningUpProvider>
        <SafeAreaProvider>
          <Provider store={store}>
            <Navigation/>
          </Provider>
          {/* <Toast ref={(ref: any) => Toast.setRef(ref)} /> */}
          <Toast config={toastConfig}/>
          <StatusBar
            hidden={false}
            style="auto"
          />
        </SafeAreaProvider>
      </SigningUpProvider>
    );
}