import React, { useState, useEffect, useRef } from 'react';
import MapView from 'react-native-map-clustering';
// import MapView from 'react-native-maps';
import { Marker, PROVIDER_DEFAULT, PROVIDER_GOOGLE } from 'react-native-maps';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import * as Location from 'expo-location';
import Fire from '../Fire';
import { updateUserDataProperty } from '../store/slices/userData';
import getZipFromLatLng from '../helpers/getZipFromLatLng';
import catchErrorForSentry from '../helpers/catchSentryError';
import MapImageMarker from '../components/MapImageMarker';
import MapMarkerPopUp from '../components/MapMarkerPopUp';
import { COLORS, DEBUG_EMAILS, INITIAL_REGION, SCREEN_SIZE } from '../Constants';
import Constants from 'expo-constants';
import registerForPushNotificationsAsync from '../helpers/registerPushNotificationsAsync';
import TouchableContainer from '../components/TouchableContainer';
import { Platform, View, StyleSheet, Text } from 'react-native';
import { Entypo, Feather, FontAwesome, Ionicons } from '@expo/vector-icons';
import { UserData } from '../Interfaces';
import arePropertiesTruthy from '../helpers/arePropertiesTruthy';
import isWeb from '../helpers/isWeb';
import getRndFloat from '../helpers/getRndFloat';
import { requestTrackingPermissionsAsync } from 'expo-tracking-transparency';
import blurLocation from '../helpers/blurLocation';
import isObjEmpty from '../helpers/isObjEmpty';
import confirmDecision from '../helpers/confirmDecision';
import { updateGuestDataProperty } from '../store/slices/guestData';


let ExpoTrackingTransparency: any;
if (Platform.OS === 'ios') {
  ExpoTrackingTransparency = require('expo-tracking-transparency');
}
const MapScreen = (props: any)=>{
    const userData = useSelector((state: RootState)=>state.userData);
    const dispatch = useDispatch();

    const [filter, setFilter] = useState<UserData["accountType"]>("account");
    const [location, setLocation] = useState<any>(null);
    const [allAccountData, setAllAccountData] = useState<any>(null);
    const [allUserData, setAllUserData] = useState<any>(null);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [region, setRegion] = useState(INITIAL_REGION);
    const [tracking, setTracking] = useState(false);
    const [zoomLevel, setZoomLevel] = useState({
      latitudeDelta: 0.01,
      longitudeDelta: 0.01
    }); // Initial zoomed-in view
    const [userInteracted, setUserInteracted] = useState(false);
    const zoomLevelRef = useRef(zoomLevel);

    // Update the ref in an effect
    useEffect(() => {
      if(!location) return;
      // console.log("Location: ", location);
      // console.log("Region: ", region);
      const newRegion = {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        latitudeDelta: zoomLevel.latitudeDelta,
        longitudeDelta: zoomLevel.longitudeDelta
      };
      if (
        region.latitude === INITIAL_REGION.latitude &&
        region.longitude === INITIAL_REGION.longitude &&
        region.latitudeDelta === INITIAL_REGION.latitudeDelta &&
        region.longitudeDelta === INITIAL_REGION.longitudeDelta
      ) {
        // console.log("Setting region: ", newRegion);
        setRegion(newRegion);
      }
    }, [location]);
  
    // // Update the ref in an effect
    useEffect(() => {
      zoomLevelRef.current = zoomLevel;
    }, [zoomLevel]);
  
    useEffect(() => {
      // console.log("UserData Uid: ", userData.uid);
      // console.log("UserData Birthday: ", userData.birthday);
      if(userData.uid && !userData.birthday){
        console.log(`${userData.username} does not have a birthday! ${userData.birthday}`);
        // console.log("UserData: ", userData);
        props.navigation.navigate('Birthday');
        return;
      }
      (async () => {
        if (Platform.OS === 'ios' && ExpoTrackingTransparency) {
          const { status } = await ExpoTrackingTransparency.requestTrackingPermissionsAsync();
          if (status === 'granted') {
            console.log('Yay! I have user permission to track data');
          } else {
            console.log("Permission to collect data was denied - Status: ", status);
            setErrorMsg('Permission to collect data was denied');
            catchErrorForSentry(status);
          }
        }
      })();
      // (async () => {
      //   const { status } = await requestTrackingPermissionsAsync();
      //   if (status === 'granted') {
      //     // console.log('Yay! I have user permission to track data');
      //   }
      //   else{
      //     console.log("Permission to collect data was denied - Status: ", status);
      //     setErrorMsg('Permission to collect data was denied');
      //     catchErrorForSentry(status);
      //   }
      // })();

      (async ()=>{
        if(!userData.uid) return;
        if(userData.pushNotificationsOn) return;
        const token = await registerForPushNotificationsAsync();
        if(!token) return;
        await Fire.setUserDataRoute(`${userData.uid}/pushToken`, token);
        await Fire.setUserDataRoute(`${userData.uid}/pushNotificationsOn`, true);
        dispatch(updateUserDataProperty({property: "pushToken", value: token}))
        dispatch(updateUserDataProperty({property: "pushNotificationsOn", value: true}))
      })();
      (async ()=>{

          const data = await Fire.getAllUserData();
          if(data){
            // console.log("Account data: ", data);
            setAllUserData(data);
          }
          else new Error("Failed to fetch data!", data)

      })();
      (async () => {
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
          console.log("Access was denied - Status: ", status);
          setErrorMsg('Permission to access location was denied');
          catchErrorForSentry(status);
          return;
        }
        
        
        try {
          let newLocation = await Location.getCurrentPositionAsync({
            accuracy: Location.Accuracy.High
          });
    
          setLocation(newLocation);
          if (!userData.uid){
            const { latitude, longitude } = newLocation.coords
            dispatch(updateGuestDataProperty({ property: "coords", value: { latitude, longitude } }));
            return;
          };
          let blurredLocation = null;
          if(userData.accountType === 'account') return;

          blurredLocation = blurLocation(newLocation.coords);
          const { latitude, longitude } = blurredLocation.coords;
          // ... Update Firebase and Redux store with new location
          Fire.updateUserLocation(userData.uid, { latitude, longitude });
          Fire.updateUserDataRoute(`${userData.uid}/coords`, { latitude, longitude });
          dispatch(updateUserDataProperty({ property: "lastLocation", value: { latitude, longitude } }));
          dispatch(updateUserDataProperty({ property: "coords", value: { latitude, longitude } }));
    
        } catch (error) {
          console.error("Error fetching the user's location:", error);
        }
      })();

    }, []);

    const renderMarkers = ()=>{
      // if(!userData.uid) return;
      if(isObjEmpty(allUserData)) return;
      const filteredUsers =  Object.values(allUserData).filter((account: any)=>{
        const accountEmail = account.email ? account.email.toLowerCase() : "";
        if(DEBUG_EMAILS.includes(accountEmail)) return false;
        if(account.uid === userData.uid) return false;
        if(!account.coords) return false;
        //Include staff, when user filter is used
        // if(filter === 'user' && account.accountType === 'staff') return true;
        if(account.accountType === filter) return true;
        
      });
      // console.log("Filtered Users: ", filteredUsers);
      const markers = []
      for (let account of Object.values(filteredUsers)){
        //@ts-ignore
        const {uid, displayName, profilePictureKey, email, dealOne, dealTwo} = account;
        //@ts-ignore
        const coords = ( (uid === userData.uid) && location ) ? location.coords : account.coords;
        const hasDeals = userData.accountType === 'account' ? arePropertiesTruthy(dealOne) || arePropertiesTruthy(dealTwo) : false;

        markers.push(
          //@ts-ignore
          <Marker
          key={uid}
          // identifier={`${uid}`}
          coordinate={coords}
          tracksViewChanges={true}
        >
          <MapImageMarker
          //   forceStateChange={state.forceStateChange}
              markerData={{
                  // style: {zIndex: 2},
                  key: uid,
                  // identifier: uid,
                  coordinate: coords,
                  title: displayName,
              }}
              style={{
                  height: 50,
                  width: 50,
                  borderRadius: 100 / 2,
              }}
              profilePictureKey={profilePictureKey}
              username={displayName}
              isVendor={true}
              online={false}
              hasDeals={hasDeals}
          />
          <MapMarkerPopUp
              key={uid}
              userData={account}
              navigation={props.navigation}
          />
        </Marker>
        )
      }
      // const markers = filteredUsers.map((account: any)=>{
      //   // return <UserMarker key={account.uid} userData={account} location={{coords: account.coords}}/>
      //   const {uid, displayName, profilePictureKey, email, dealOne, dealTwo} = account;
      //   const coords = ( (uid === userData.uid) && location ) ? location.coords : account.coords;
      //   const hasDeals = userData.accountType === 'account' ? arePropertiesTruthy(dealOne) || arePropertiesTruthy(dealTwo) : false;
      //   return(
      //     <Marker
      //       key={uid}
      //       // identifier={`${uid}`}
      //       coordinate={coords}
      //       tracksViewChanges={false}
      //     >
      //       <MapImageMarker
      //       //   forceStateChange={state.forceStateChange}
      //           markerData={{
      //               // style: {zIndex: 2},
      //               key: uid,
      //               // identifier: uid,
      //               coordinate: coords,
      //               title: displayName,
      //           }}
      //           style={{
      //               height: 50,
      //               width: 50,
      //               borderRadius: 100 / 2,
      //           }}
      //           profilePictureKey={profilePictureKey}
      //           username={displayName}
      //           isVendor={true}
      //           online={false}
      //           hasDeals={hasDeals}
      //       />
      //       <MapMarkerPopUp
      //           key={uid}
      //           userData={account}
      //           navigation={props.navigation}
      //       />
      //     </Marker>
      //   )
      // })
      return markers;
    }

    const TrackingModeButton = ()=>{
      return(
        <View style={{
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignSelf: 'flex-end',
                width: SCREEN_SIZE.width * .35,
                bottom: 5,
                position: 'absolute',
        }}>
              <TouchableContainer onPress={async ()=>{
                  if(tracking) return setTracking(false);
                  const result = await confirmDecision('Allow “Always Local” to use your location?', 'Always Local requires access to you location in order to display “local” Establishments-Specials-Events \nProceed?')
                  // const result = await confirmDecision('Entering Tracking Mode', 'In tracking mode, the camera will follow your location so that you can see local locations as you explore! \nProceed?')
                  if(!result) return;
                  setTracking(true);
              }}>
                <View style={{
                  ...styles.filterBtnContainer,
                  backgroundColor: (tracking ? COLORS.primaryBlue : '#fff')
                  }}>
                  <FontAwesome style={{alignSelf: 'center'}} name="car" size={24} color={tracking  ? 'white' : 'black'} />
                </View>
              </TouchableContainer>
        </View>
      )
    }

    const renderFilterButtons = ()=>{
        return(
            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignSelf: 'flex-end',
                width: SCREEN_SIZE.width * .35,
                bottom: 5,
                position: 'absolute',
            }}>

          

              <TouchableContainer onPress={()=>{
                  setFilter('account');
              }}>
                <View style={{
                  ...styles.filterBtnContainer,
                  backgroundColor: (filter === 'account' ? COLORS.primaryBlue : '#fff')
                  }}>
                  <Entypo style={{alignSelf: 'center'}} name="drink" size={24}
                  color={filter === 'account'  ? 'white' : 'black'} />
                </View>
              </TouchableContainer>

              <TouchableContainer onPress={()=>{
                  setFilter('user');
              }}>
                <View style={{
                  ...styles.filterBtnContainer,
                  backgroundColor: (filter === 'user' ? COLORS.primaryBlue : '#fff')
                  }}>
                  <Ionicons
                    name={'people'}
                    size={25} 
                    color={filter === 'user'  ? 'white' : 'black'}
                    style={{alignSelf: 'center'}}
                  />
                </View>
              </TouchableContainer>

              <TouchableContainer onPress={()=>{
                  setFilter('entertainment');
              }}>
                <View style={{
                  ...styles.filterBtnContainer,
                  backgroundColor: (filter === 'entertainment' ? COLORS.primaryBlue : '#fff')
                  }}>
                  <Feather
                    style={{alignSelf: 'center'}} name="music" size={24}
                    color={filter === 'entertainment' ? 'white' : 'black'}
                    />
                </View>
              </TouchableContainer>
          </View>
        )
    }

    const handleRegionChangeComplete = (newRegion: any) => {
      if (userInteracted) {
        // If user interacted, set the new zoom level
        console.log("Set zoom level from user interaction");
        console.log("Zoom level set to");
        console.log("latitudeDelta: ", newRegion.latitudeDelta);
        console.log("longitudeDelta: ", newRegion.longitudeDelta);
        setZoomLevel({
          latitudeDelta: newRegion.latitudeDelta,
          longitudeDelta: newRegion.longitudeDelta
        });
        setUserInteracted(false);
      }
      // If not, the zoom level remains the same.
    };

  
    return(
        <View style={styles.mapContainer}>
          <MapView
              // onPanDrag={() => setUserInteracted(true)}
              // onRegionChangeComplete={handleRegionChangeComplete}
              showsUserLocation={true}
              followsUserLocation={tracking ? Platform.OS === 'ios' ? true : false : false}
              tracksViewChanges={false}
              clusterColor={COLORS.primaryBlue}
              style={styles.map}
              provider={Platform.OS === 'ios' ? PROVIDER_DEFAULT : PROVIDER_GOOGLE}
              toolbarEnabled={false}
              initialRegion={region}
              region={region}
          >
            {/* {location && <UserMarker userData={userData} location={location} />} */}
            {renderMarkers()}
          </MapView>
          {/* {renderFilterButtons()} */}
          <TrackingModeButton/>
        </View>
    )
}

const styles = StyleSheet.create({
  filterBtnContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 20
  },
  mapContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  map: {
    ...StyleSheet.absoluteFillObject,
  },
})

export default MapScreen;

