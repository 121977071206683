import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import EntryScreen from '../../screens/EntryScreen';
import MapScreen from '../../screens/MapScreen';
import UserProfile from '../../screens/UserProfile';
import MobileHeader from './MobileHeader';
import MenuScreen from '../../screens/MenuScreen';
import { FontAwesome5, Ionicons, SimpleLineIcons } from '@expo/vector-icons';
import ExploreAccounts from '../../screens/ExploreAccounts';
import Messenger from '../../screens/Messenger';
import AccountProfile from '../../screens/AccountProfile';
import BirthdayScreen from '../../screens/BirthdayScreen';
import { Alert, Text } from 'react-native';
import promptUser from '../../helpers/promptUser';
import NotificationScreen from '../../screens/NotificationScreen';
import ChatScreen from '../../screens/ChatScreen';
import ChatScreenHeaderTitle from '../../components/ChatScreenHeaderTitle';
import UserSearchScreen from '../../screens/UserSearchScreen';
import isObjEmpty from '../../helpers/isObjEmpty';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';
import AccountDataEditor from '../../screens/AccountDataEditor';
import BusinessHours from '../../screens/BusinessHours';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import HamburgerMenu from './HamburgerMenu';
import UnseenMessagesCount from '../../components/UnseenMessagesCount';
import NotificationsHeader from './NotificationsHeader';
import ProfileSelect from '../../screens/ProfileSelect';
import UserSelectScreen from '../../screens/UserSelectScreen';
import InviteContacts from '../../screens/InviteContacts';



const Drawer = createDrawerNavigator();
function SettingsScreen({ navigation }: any) {
  console.log("Rendering settings drawer");
  return (
    <Drawer.Navigator
      screenOptions={{
        drawerPosition: 'right', // Drawer will come out from the right
        drawerType: 'slide', // Slide animation
      }}
    >
      <Drawer.Screen name="Settings" component={MenuScreen} />
      {/* <Drawer.Screen
        name="Logout"
        component={LogoutScreen} // Assuming you have a LogoutScreen or some function to handle logout
      /> */}
      {/* Add other screens or functions as needed */}
    </Drawer.Navigator>
  );
}

const Tab = createBottomTabNavigator();
const HomeScreen = () => {
  return (
  <Tab.Navigator
    screenOptions={({ route }) => ({
      tabBarActiveTintColor: 'black',
      tabBarInactiveTintColor: 'gray',
      tabBarIcon: ({ color, size }) => {
        let iconName;
        if (route.name === 'MapScreen') {
          return <FontAwesome5 name="map-marked-alt" size={size} color={color} />;
        } else if (route.name === 'Profile') {
          return <Ionicons name="person-sharp" size={size} color={color} />;
        } else if (route.name === 'Explore') {
          return <FontAwesome5 name="building" size={size} color={color} />
        } else if (route.name === 'Messenger') {
          return(
            <>
              <SimpleLineIcons name="people" size={size} color={color} />
              <UnseenMessagesCount/>
            </>
          )
        }

        // You can return any component that you like here!
        // return <Icon name={iconName} size={size} color={color} />;
      },
      
    })}
  >
    <Tab.Screen options={{ tabBarLabel: 'Map' }} name="MapScreen" component={MapScreen} />
    <Tab.Screen options={{ tabBarLabel: 'Explore' }} name="Explore" component={ExploreAccounts} />
    <Tab.Screen options={{ tabBarLabel: 'Messenger' }} name="Messenger" component={Messenger} />
    <Tab.Screen options={{ tabBarLabel: 'Profile', headerShown: true }} name="Profile" component={UserProfile} />
  </Tab.Navigator>
)};

const Stack = createNativeStackNavigator();
const MobileNavigator=({ route }: any)=>{
  const userData = useSelector((state: RootState)=>state.userData);
  const isLoggedIn = userData.uid ? true : false;
  const isBirthdaySet = userData.birthday !== 0;
  // const initialRouteName = isLoggedIn && userData.emailVerified ? 'Home' : 'Entry';
  const initialRouteName = isLoggedIn ? (isBirthdaySet ? 'Home' : 'Birthday') : 'Entry';
  // console.log("Is Birthday set: ", isBirthdaySet);
  // console.log("")
  // console.log("Is logged in: ", isLoggedIn);
    return (
      <Stack.Navigator initialRouteName={initialRouteName}>
        {/* {(!userData.emailVerified || !isLoggedIn ) && <Stack.Screen */}
        <Stack.Screen
              name="Entry"
              component={EntryScreen}
              options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Home"
          component={HomeScreen}
          options={({ navigation, route }) => ({
            headerShown: true,
            header: ()=>(
              <MobileHeader
                route={route}
                navigation={navigation}
                isLoggedIn={isLoggedIn}
                openDrawer={() => navigation.openDrawer()} // Add this line to open the drawer
              />
            )
          })}
        />
        <Stack.Screen
            name="Birthday"
            component={BirthdayScreen}
            options={({ route }: any) =>{
              return { noBirthday: !userData.birthday, headerShown: false, title: "Set your birthdate" }
            }}
          />
        <Stack.Screen
          name="MenuScreen"
          component={SettingsScreen}
          options={{ headerShown: false }}
        />
        {/* <Stack.Screen
          name="MenuScreen"
          component={MenuScreen}
          options={()=>{
            return {
              title: "Settings" 
            }
          }}
          // options...
        /> */}
        <Stack.Screen
          name="AccountProfile"
          component={AccountProfile}
          options={({ route }: any) =>{
            const pName =  route && route.params && route.params.displayName;
            return { title:  pName || userData.displayName}
          }}
        />
        <Stack.Screen
          name="UserProfile"
          component={UserProfile}
          options={({ route }: any) =>{
            const pName =  route && route.params && route.params.displayName;
            return { title:  pName || userData.displayName}
          }}
        />
        <Stack.Screen
          name="ProfileSelect"
          component={ProfileSelect}
          options={({ route }: any) =>{
            const pName =  route && route.params && route.params.displayName;
            return { title:  "Select profile"}
          }}
        />
        <Stack.Screen
          name="UserSelectScreen"
          component={UserSelectScreen}
          options={({ route }: any) =>{
            const pName =  route && route.params && route.params.displayName;
            return { title:  "Select profile"}
          }}
        />
        <Stack.Screen
          name="AccountDataEditor"
          component={AccountDataEditor}
          options={({ route }: any) =>{
            return { title: 'Account Settings'}
          }}
        />
        <Stack.Screen
          name="NotificationScreen"
          component={NotificationScreen}
          options={({ navigation, route }) => ({
            headerShown: true,
            header: ()=>(
              <NotificationsHeader
                route={route}
                navigation={navigation}
                isLoggedIn={isLoggedIn}
              />
            )
          })}
          // options={({ route }: any) =>{
          //   return { title:  "Notifications"}
          // }}
        />
        <Stack.Screen
          name="ChatScreen"
          component={ChatScreen}
          options={({ navigation, route }: any) =>{
            return { header:  (props: any)=><ChatScreenHeaderTitle {...props} route={route} navigation={navigation}/>}
          }}
        />
        <Stack.Screen
          name="UserSearchScreen"
          component={UserSearchScreen}
          options={({ navigation, route }: any) =>{
            return { title: 'Search for users'}
          }}
        />
        <Stack.Screen
          name="InviteContacts"
          component={InviteContacts}
          options={({ navigation, route }: any) =>{
            return { title: 'Invite your contacts'}
          }}
        />
        <Stack.Screen
          name="BusinessHours"
          component={BusinessHours}
          options={({ navigation, route }: any) =>{
            // const pName =  route && route.params && route.params.displayName;
            return { title: `Hours`}
          }}
        />
      </Stack.Navigator>
    );
}


const DrawerNavigator = ({ isLoggedIn, userData }: any) => {
  return (
    <Drawer.Navigator drawerContent={(props) => <HamburgerMenu {...props} />}
      screenOptions={{
        drawerPosition: 'left', // Drawer will come out from the right
        drawerType: 'slide', // Slide animation
        headerShown: false,
      }}
    >
      <Drawer.Screen
        name="MobileNavigator"
        component={MobileNavigator}
        options={{
          drawerItemStyle: { display: 'none' },
        }}
      />
      {/* Add other screens or options as needed */}
    </Drawer.Navigator>
  );
};

export default DrawerNavigator;