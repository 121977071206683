import { deviceYearClass } from "expo-device";
import { Dimensions, Platform } from "react-native";
import { Edge } from "react-native-safe-area-context";
import { Gig } from "../types";

export const DEBUG_EMAILS = [
  'accounttest@gmail.com', 'tyfooods@gmail.com', 'test@gmail.com', 'test0@gmail.com',
]

export const filterTypes = [
    "Restaurant",
    "Bar",
    "Brewery",
    "Food Truck",
];

export const isDeviceOld = deviceYearClass && (deviceYearClass <= 2015);

const isMobile = () => {
  if (typeof navigator === 'undefined') {
    return false;
  }

  const userAgent = navigator.userAgent || navigator.vendor;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};

export const EDGES: {[key: string]: any} = {
  TOP: ["top"],
  BOTTOM: ["bottom"],
  LEFT: ["left"],
  RIGHT: ["right"],
  ALL: ['top', 'right', 'bottom', 'left']
}

// export const GOOGLE_MAPS_API_KEY = 'AIzaSyCF0c6sUuW6ovX9RkVXX_R7JRJB2QeqNYA'
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBJzXT_XilsJlQg0qQA80Zdm4Gawd63yG4'

export const INITIAL_REGION = {
  latitude: 26.646301546567997,
  latitudeDelta: 4.4573366241774615,
  longitude: -81.60360652953386,
  longitudeDelta: 3.7030912935733795
}

export const SCREEN_SIZE: any = (()=>Platform.OS === 'web' ? Dimensions.get('window') : Dimensions.get('screen'))()

export const mediaUrl = "";

export const S3_URL = 'https://alwayslocal-pictures.s3.us-east-2.amazonaws.com';

export const CLOUDFRONT_URL = 'https://d1omgkvv2i9cjh.cloudfront.net';

// export const IMAGEKIT_URL = 'https://ik.imagekit.io/fam/alwayslocal/';
export const IMAGEKIT_URL = 'https://ik.imagekit.io/alwayslocal/';

const DEBUG = false;
//10
export const SERVER_URL = DEBUG ? 'http://localhost:3001' : 'https://alwayslocal-server.herokuapp.com'
// export const SERVER_URL = DEBUG ? 'http://73.49.33.37:3001' : 'https://alwayslocal-server.herokuapp.com'
// export const SERVER_URL = DEBUG ? 'http://192.168.56.1:3001' : 'https://alwayslocal-server.herokuapp.com'

export const pictureBucket = 'alwayslocal-pictures';

export const videoBucket = 'alwayslocal-videos';

export const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// Vodka.              Tequila               Gin.                      Rum 
// Bourbon            Whiskey            Scotch                  Non Alcoholic 
// Craft Beer         Import Beer      Domestic Beer     Seltzer
// White  Wine      Red Wine          Champagne         Prosecco
export const drinks = [
  'Vodka',
  'Tequila',
  'Gin',
  'Rum',

  'Bourbon',
  'Whiskey',
  'Scotch',
  'Non Alcoholic',

  'Craft Beer',
  'Import Beer',
  'Domestic Beer',
  'Selzter',

  'Red Wine',
  'White Wine',
  'Champagne',
  'Prosecco',
  // 'Gluten Free',
]


export const ACCOUNT_TYPE = {account: 'account', user: 'user', brand: 'brand', entertainment: 'entertainment'}

//This is for scaling components etc across various devices
export const SCALE_VALUE = 2;

export const errorMessages = {
  usernameError: 'Usernames can only contain up to 30 of these characters: a-z, 0-9, _ and . (only in the middle)',
  firstNameError: 'First names must start with a capital letter and can only contain up to 30 characters',
  lastNameError: 'Last names must start with a capital letter and can only contain up to 30 characters',
  phoneNumberError: 'Phone number provided is not a valid US phone number',
  zipcodeError: 'Zipcode is not a valid US zipcode',
  emailError: 'Email must be valid',
  passwordError: 'Your password must be 6 characters or longer',
  websiteError: "There is an error with your url format! - Must have https://, or https:// at the beginning"
}

export const regexPatterns= {
    phoneNumber: /^\d{0,10}$/,
    zipcode: /^\d{0,6}$/,
    name: /^[A-Z][a-z]+$/,
    username: /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/,
    email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    password: /^.{6,}$/,
    url: new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)?(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i'),
    description: /^[\s\S]{0,100}$/,
    genre: /^.{0,20}$/,
}

export const COLORS ={
  messengerGray: '#EFEFEF',
  primaryRed: '#DD222E',
  primaryGray: '#F8FAFC',
  slateGray: '#232323',
  // primaryGray: '#3B4044',
  // light: {
  //   text: '#000',
  //   background: '#fff',
  //   tint: tintColorLight,
  //   tabIconDefault: '#ccc',
  //   tabIconSelected: tintColorLight,
  // },
  // dark: {
  //   text: '#fff',
  //   background: '#000',
  //   tint: tintColorDark,
  //   tabIconDefault: '#ccc',
  //   tabIconSelected: tintColorDark,
  // },
  inactiveTabColor: '#D0CECE',
  primaryTextColor: '#011C42',
  primaryBgColor: '#F5F5F5',
  primaryOrange: '#FF9026',

  greenAccent: '#25b820',

  //This is from Flow Arts Meetup
  primaryBlue: '#4285f4',
  // primaryRed: '#B82025',
  primaryPurple: '#901680',
  primaryGreen: '#25b820',
  // primaryGray: '#f2f2f2',
  grayText: '#617D8A',
}

export const fakeGigData: Gig[] = [
  {
    id: '1',
    date: new Date('2024-12-10').getTime(),
    start: 14 * 60, // 2:00 PM in minutes since midnight
    end: 16 * 60,   // 4:00 PM in minutes since midnight
    employer: 'Company A',
    employee: 'Food Truck A',
    type: "food",
    location: "",
  },
  {
    id: '2',
    date: new Date('2024-12-10').getTime(),
    start: 14 * 60, // 2:00 PM in minutes since midnight
    end: 16 * 60,   // 4:00 PM in minutes since midnight
    employer: 'Company B',
    employee: 'Food Truck B',
    type: "food",
    location: "",
  },
  {
    id: '3',
    date: new Date('2024-12-10').getTime(),
    start: 14 * 60, // 2:00 PM in minutes since midnight
    end: 16 * 60,   // 4:00 PM in minutes since midnight
    employer: 'Company C',
    employee: 'Food Truck C',
    type: "food",
    location: "",
  },
  {
    id: '4',
    date: new Date('2024-12-28').getTime(),
    start: 12 * 60, // 12:00 PM in minutes since midnight
    end: 14 * 60,   // 2:00 PM in minutes since midnight
    employer: 'Company A',
    employee: 'Entertainer A',
    type: "entertainment",
    location: "",

  },
  {
    id: '5',
    date: new Date('2024-12-30').getTime(),
    start: 20 * 60, // 8:00 PM in minutes since midnight
    end: 22 * 60,   // 10:00 PM in minutes since midnight
    employer: 'Company B',
    employee: 'Entertainer B',
    type: "entertainment",
    location: "",

  },
  {
    id: '6',
    date: new Date('2024-12-31').getTime(),
    start: 13 * 60, // 1:00 PM in minutes since midnight
    end: 15 * 60,   // 3:00 PM in minutes since midnight
    employer: 'Company C',
    employee: 'Entertainer C',
    type: "entertainment",
    location: "",
  },
];