import { Linking, StyleSheet, Text, View, useWindowDimensions } from "react-native";
import WebFooter from "../components/WebFooter";
import { COLORS, SCREEN_SIZE } from "../Constants";
import LoginCard from "../components/LoginCard";
import SignUpCard from "../components/SignUpCard";
import { useState } from "react";
import CustomButton from "../components/CustomButton";
import scaleFontSize from "../helpers/scaleFontSize";
import { Screen } from "../components/Screen";
import { useHeaderHeight } from '@react-navigation/elements';
import isMobile from "../helpers/isMobile";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import ensureProtocol from "../helpers/ensureProtocol";
import openAppStore from "../helpers/openAppStore";


const WebAuthPage = (props: any)=>{
    const [ showSignUpCard, setShowSignUpCard ] = useState(true);
    const [ showLoginButton, setShowLoginButton ] = useState(true);
    // const windowSize = useWindowDimensions();
    // const headerHeight = useHeaderHeight();

    const LoginButton = ()=>{
        return(
            <CustomButton
                onPress={()=>{
                    setShowLoginButton(false);
                    setShowSignUpCard(false);
                }}
                title={"Login"}
                textStyle={{fontSize: scaleFontSize(15), fontWeight: 700}}
                style={{
                    marginTop: 10,
                    borderRadius: 10,
                    backgroundColor: COLORS.primaryRed,
                    // width: windowSize.width * .15,
                    minWidth: 200,
                    // width: 125,
                    height: 50,
                    justifyContent: 'center',
                }}
            />
        )
    }

    const { top } = useSafeAreaInsets();
    const headerHeight = useHeaderHeight();
    return(
        <View style={{
            ...styles.container,
            marginTop: top + headerHeight/5,
            // borderColor: 'blue',
            // borderWidth: 1,
            justifyContent: 'center',
        }}>
            <View style={{
                flex: 1,
                width: '100%',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                // borderColor: 'green',
                // borderWidth: 1,
            }}>
                    <SignUpCard
                        // key={`${showLoginButton} and ${showSignUpCard}`}
                        navigation={props.navigation}
                        showLoginButton={showLoginButton}
                        setShowLoginButton={setShowLoginButton}
                        showSignUpCard={showSignUpCard}
                        setShowSignUpCard={setShowSignUpCard}
                        style={{
                            backgroundColor: COLORS.primaryBlue,
                            flex: 4.75/5,
                            width: isMobile() ? '95%' : '65%',
                            justifyContent: 'center',
                            alignItems: 'center', 
                            padding: 10,
                            paddingVertical: 30,
                            alignSelf: 'center',
                            maxWidth: 650,
                        }}
                    >
                        <Text
                            style={{marginTop: 10, width: '50%', color: 'white',alignSelf: 'center', fontSize: scaleFontSize(12), textAlign: 'center'}}>
                            Note: Users, Staff and Entertainment must make their account at
                            <Text onPress={openAppStore}
                                style={{color: 'lightblue', textDecorationLine: 'underline', textDecorationColor: 'lightblue', fontWeight: '500'}}> this link
                            </Text>
                        </Text>
                        {
                            showLoginButton ?
                            <View style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '5%',
                            }}>
                                <Text style={{fontSize: scaleFontSize(15), fontWeight: '500', color: '#fff'}}>
                                    Already have an account?
                                </Text>
                                <LoginButton/>
                            </View>
                            :
                            <LoginCard
                                setShowLoginButton={setShowLoginButton}
                                setShowSignUpCard={setShowSignUpCard}
                                navigation={props.navigation}
                                showLoginButton={showLoginButton}
                                style={{
                                    // display: showSignUpCard ? 'auto' : 'none',
                                    paddingVertical: 30
                            }}/>
                        }
                        
                    </SignUpCard>
                
            </View>
            <WebFooter style={{
                // position: 'absolute',
                // bottom: 0,
                // borderColor: 'red',
                // borderWidth: 1,
                // height: SCREEN_SIZE.height * .045
            }}/>
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
        // flex: 1,
        // borderColor: 'red',
        // borderWidth: 1,
        height: SCREEN_SIZE.height,
        width: SCREEN_SIZE.width,
        backgroundColor: '#F8FAFC',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
    },

})

export default WebAuthPage;