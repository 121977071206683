import React, { useState, useEffect } from 'react';
import { StyleSheet, FlatList, View, TextInput, Text, TouchableHighlight, ActivityIndicator } from 'react-native';
import Card from '../components/Card';
import { COLORS, DEBUG_EMAILS, IMAGEKIT_URL, SCREEN_SIZE } from '../Constants/';
import Fire from '../Fire';
import isObjEmpty from '../helpers/isObjEmpty';
import UserImage from '../components/UserImage';
import { UserData } from '../Interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import confirmDecision from '../helpers/confirmDecision';
import addNotificationToUser from '../helpers/addNotificationToUser';
import catchErrorForSentry from '../helpers/catchSentryError';
import sendToProfileScreen from '../helpers/sendToProfileScreen';
import scaleFontSize from '../helpers/scaleFontSize';
import TouchableContainer from '../components/TouchableContainer';


const UserSearchScreen = (props: any)=>{
    const {navigation, route} = props;
    const userData = useSelector((state: RootState) => state.userData);
    // const mode = route.params;
    const userIsStaff = userData.accountType === 'staff';
    const userIsAccount = userData.accountType === 'account';
    const userIsEntertainment = userData.accountType === 'entertainment';
    const [allUsersData, setAllUsersData] = useState({});
    const [ userListData, setUserListData] = useState({});
    const [ freshUserListData, setFreshUserListData] = useState({});
    const [ searchQuery, setSearchQuery] = useState("");
    const [ loaded, setLoaded] = useState(false);


    useEffect(()=>{

        if(!isObjEmpty(freshUserListData)){
            //@ts-ignore
            setUserListData(sortUsersAlphabetically(freshUserListData.filter((user: UserData) => {     
                return filterSearchData(user);
            })));

        }
    }, [searchQuery])

    const sortUsersAlphabetically = (arr: UserData[]) => {
        const filteredArr = arr.filter((user)=>{
            if(!user.username) catchErrorForSentry(new Error("ALERT: Undefined username in publicUserData"));
            return user.username
        })
        return filteredArr.sort((a, b) => {
            console.log("A: ", a.username, "B: ", b.username);
            return a.username.localeCompare(b.username);
        });
    };
    

    useEffect(()=>{
        Fire.getAllUserData()
        .then((data: UserData[])=>{
            if(!data) return setLoaded(true);;
            const allUserData = sortUsersAlphabetically(Object.values(data).filter((user: any)=>{
                if(DEBUG_EMAILS.includes(userData.email.toLowerCase())) return true;
                return(
                    //User is valid
                    user.uid &&
                    //User isn't a debug user
                    !DEBUG_EMAILS.includes(user.email.toLowerCase())
                )
            }));
            setUserListData(allUserData);
            setFreshUserListData(allUserData);
            setLoaded(true);
        })
        .catch((err: any)=>{
            console.log("Error: ", err);
            catchErrorForSentry(err);
            setLoaded(true);
        })

    }, []);

    const requestType = (profileData: UserData)=>{
        const profileIsStaff = profileData.accountType === 'staff';
        const profileIsAccount = profileData.accountType === 'account';
        const profileIsEntertainment = profileData.accountType === 'entertainment';
        if(profileIsAccount && userIsStaff || profileIsStaff && userIsAccount) return 'work';
        if(profileIsAccount && userIsEntertainment || profileIsEntertainment && userIsAccount) return 'entertainment';
    }

    const requestTypeText = (profileData: UserData)=>{
        const profileIsStaff = profileData.accountType === 'staff';
        const profileIsAccount = profileData.accountType === 'account';
        const profileIsEntertainment = profileData.accountType === 'entertainment';
        if(profileIsAccount && userIsStaff) return 'an employer'
        if(profileIsStaff && userIsAccount) return 'an employee';
        if(profileIsAccount && userIsEntertainment) return 'an entertainment client';
        if(profileIsEntertainment && userIsAccount) return 'an entertainer';
    }

    const addNewRequest = (profileData: UserData)=>{
        Fire.getDataAtRoute(`publicUserData/${profileData.uid}/`)
        .then(()=>{
            const resType = requestType(profileData);
            addNotificationToUser(profileData.uid, {
                id: `${userData.uid}-${resType}-request`,
                image: `${IMAGEKIT_URL}/${profileData.profilePictureKey}`,
                content: `${userData.displayName} requested you as ${requestTypeText(profileData)}!`,
                timeCreated: new Date().getTime(),
                read: false,
                //When notification is pressed it won't direct user to post screen.
                type: {request: userData.uid, type: resType},
            });
        })
        .catch((error: any)=>{
            catchErrorForSentry(error);
        })
    }

    const onUserPress = async (profileData: UserData)=>{
        const typeText = requestTypeText(profileData);
        const response = await confirmDecision(
            `Are you sure you would like to add ${userData.displayName} as ${typeText}?`,
            `Once they accept your request they will be added to your profile`
        )
        if(!response) return;
        addNewRequest(profileData);
    }

    const filterSearchData = (user: UserData)=>{
        if(user.blockList){
            let currentUserUID = userData.uid;
            
            let userBlockList = Object.keys(user.blockList);
            if(userBlockList.includes(currentUserUID)){
                return false;
            }
            else{
                // console.log("User has a block list and ")
                if(user.username){
                    return user.username.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
                }
            }
        }
        else{
            if(user.username){
                const matchedUsername = user.username ? user.username.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 : false;
                const matchedDisplayName = user.displayName ? user.displayName.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 : false;
                if(matchedUsername) return matchedUsername
                else return matchedDisplayName
            }
        }
    }

    const renderSearchBar = ()=>{
        return(
            <View style={styles.searchBarContainer}>
                <TextInput
                    style={{
                        height: 50,
                        width: '100%',
                        backgroundColor: 'white',
                        borderColor: 'black',
                        borderWidth: 2,
                        borderRadius: 20,
                        textAlign: 'center',
                        alignSelf: 'flex-start',

                        shadowColor: 'black',
                        shadowOpacity: 0.26,
                        shadowOffset: { width: 0, height: 2 },
                        shadowRadius: 8,
                        elevation: 5,
                    }}
                    value={searchQuery}
                    placeholder={"Search..."}
                    placeholderTextColor='gray'
                    onChangeText={(value)=>{
                        setSearchQuery(value);  
                        if(isObjEmpty(userListData)) return;
                        //@ts-ignore
                        setUserListData(userListData.filter(user => {     
                            // console.log(`User ${i}: `, user);

                            return filterSearchData(user);
                        }));
                    }}
                />
            </View>
        )
    }

    const renderUserFlatList = ()=>{
        // console.log("Attempting flat list render");
        const UserBox = (data: any)=>{
            const searchedUserData: UserData = data.item;
            const {displayName, uid, profilePictureKey, username} = searchedUserData;
            // console.log("URI: ", `${IMAGEKIT_URL}/${profilePictureKey}`);
            return(
                <TouchableContainer
                    key={uid}
                    onPress={()=>sendToProfileScreen(searchedUserData.uid, navigation.push)}
                    style={{
                        flexDirection: "row",
                        alignItems: 'center',
                        width: '90%',
                        alignSelf: 'center'
                    }}
                >
                    <UserImage
                        cacheMode={true}
                        style={{height: 50, width: 50, borderRadius: 25, marginRight: 20}}
                        uri={`${IMAGEKIT_URL}/${profilePictureKey}`}
                        uid={uid}
                    />
                    <View style={{flexDirection: 'column'}}>
                        <Text style={{
                            textAlignVertical: 'center',
                            fontSize: scaleFontSize(15),
                            // borderColor: 'red', borderWidth:1
                        }}>
                            {displayName}
                        </Text>
                        <Text style={{
                            textAlignVertical: 'center',
                            fontSize: scaleFontSize(10),
                            // borderColor: 'red', borderWidth:1
                        }}>
                            {username}
                        </Text>
                    </View>
                </TouchableContainer>
            )
        }
        return(
            <FlatList
                style={{flex: 1}}
                //@ts-ignore
                data={userListData}
                //@ts-ignore
                renderItem={UserBox}
                //@ts-ignore
                keyExtractor={item =>{
                    return item.uid+item.displayName
                }}
                keyboardShouldPersistTaps='always'
                // ListHeaderComponent={searchBar}
            />
        )
    }


    return(
        <View style={styles.screen}>
            <Card style={{
                height: SCREEN_SIZE.height * .8,
                width: SCREEN_SIZE.width * .9,
                flexDirection: 'column',
                justifyContent: !loaded ? 'flex-start' : 'space-between',
                
            }}>
                {renderSearchBar()}
                {(()=>{
                   if(!loaded) return <ActivityIndicator size="large" color={COLORS.primaryBlue} />
                   if(loaded && isObjEmpty(userListData)){
                        return <Text style={{flex: 1, textAlign: 'center', fontSize: scaleFontSize(15)}}> No users found :( </Text>
                   }
                    return(
                        <>
                            {renderUserFlatList()}
                        </>
                    )
                })()}
            </Card>
        </View>
    )
}

const styles = StyleSheet.create({
    screen: {
        // justifyContent: 'center',
        padding: 10,
        alignItems: 'center',
    },
    // container: {
    //     flex: 1,
    //     marginTop: StatusBar.currentHeight || 0,
    // },
    searchBarContainer: {
        margin: 20,
        alignSelf: 'center',
        width: '90%',
    },
});


export default UserSearchScreen;