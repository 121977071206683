import { StyleSheet, View, Text, Image, ActivityIndicator, TextInput, ScrollView, useWindowDimensions, ImageBackground, Keyboard, Platform, Touchable, Linking } from "react-native";
import { useState, useRef, memo, useEffect } from 'react';
import SignUpSheet from "../navigation/components/SignUpSheet";
import { InputInterface, ValidateInputInterface } from "../Interfaces";
import { COLORS, EDGES, errorMessages, isDeviceOld, regexPatterns } from "../Constants";
import Fire from "../Fire";
import isAccountInformationTaken from "../helpers/IsAccountInformationTaken";
import AlwaysLocalLogo from "../components/AlwaysLocalLogo";
import { clearUserData, setUserData } from "../store/slices/userData";
import { useDispatch, useSelector } from "react-redux";
import catchErrorForSentry from "../helpers/catchSentryError";
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';
import { LinearGradient } from "expo-linear-gradient";
import { FontAwesome5 } from '@expo/vector-icons'; 
import TouchableContainer from "../components/TouchableContainer";
import CustomModal from "../components/CustomModal";
import ValidateInput from "../components/ValidateInput";
import { Screen } from "../components/Screen";
import { RootState } from "../store";
import scaleFontSize from '../helpers/scaleFontSize';
import CheckBox from '@react-native-community/checkbox';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { User } from "firebase/auth";
import Card from "../components/Card";
import CustomButton from "../components/CustomButton";
import EmailEditor from "../components/EmailEditor";
import confirmDecision from "../helpers/confirmDecision";
import * as Updates from 'expo-updates';
import generateInitUserData from "../helpers/generateInitUserData";
import { useSigningUp } from './SigningUpContext'; // Import the useSigningUp hook
import isZipCodeInFlorida from "../helpers/isZipCodeInFlorida";
import ensureProtocol from "../helpers/ensureProtocol";


const SignUpScreen = (props: any)=>{
    const { navigation, } = props;
    // const setIsSigningUp = navigation && route.params ? route.params : null;
    const { setIsSigningUp } = useSigningUp(); // Use the useSigningUp hook

    // console.log("Entry: ", setIsSigningUp);
    const userData = useSelector((state: RootState)=>state.userData);
    // const window = useWindowDimensions();
    const dispatch = useDispatch();
    const [showUploadPictureModal, setShowUploadPictureModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [keyboardShown, setKeyboardShown] = useState(false);
    const [funnelState, setFunnelState] = useState<"Brand" | "User" | "Account" | "">("");
    const [pictureMode, setPictureMode] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);//if remembered then default true
    const [processing, setProcessing] = useState(false);
    const [user, setUser] = useState<User>();
    const [edittingEmail, setEdittingEmail] = useState(false);
    const [emailVerified, setEmailVerified] = useState(userData.emailVerified)
    
    const [phase, setPhase] = useState({
        one: true,
        two: false,
        three: false,
    });

    const [state, setState] = useState<any>({
        username: "",
        // username: "bar1",
        // username: "tyfoods",
        usernameError: errorMessages.usernameError,
    
        firstName: "",
        firstNameError: errorMessages.firstNameError,
    
        lastName: "",
        lastNameError: errorMessages.lastNameError,
    
        phoneNumber: "",
        // phoneNumber: "+15617565951",
        // phoneNumber: "+15616742253",
        phoneNumberError: errorMessages.phoneNumberError,
        countryCode: "+1",
    
        email: "",
        // email: "anthonecuthill@gmail.com",
        // email: "tyfooods@gmail.com",
        emailError: errorMessages.emailError,

        confirmPassword: "",
        password: "",
        // password: "alwayslocal",
        passwordError: errorMessages.passwordError,

        zipcode: "",
        zipcodeError: errorMessages.zipcodeError,
    
        smsCode: "",
        error: "",
        loading: false,
    
        usernameErrorFlag: false,
        nameFlag: false,
        emailErrorFlag: false,
        phoneNumberErrorFlag: false,
        passwordErrorFlag: false,
    });

  
    useEffect(() => {
        // const onEmailVerification = (schedule: any)=>{
        //     if(!schedule) return setUidsUserFollows([]);
        //     setSchedule(schedule)
        // }
        // let scheduleRef = Fire.listenToRoute(`publicUserData/${userData.uid}`, onEmailVerification)
        const unsubscribe = Fire.getAuth().onAuthStateChanged(async (user) => {
            // if (!user) return;
            // setUser(user);
          
            // // Refresh user's profile to get the updated emailVerified status
            // await user.reload();
            // user = Fire.getAuth().currentUser;
            // if(!user) return;
            // // User is logged in and has verified their email
            // if (user.emailVerified) {
            //   setEmailVerified(true);
            //   console.log("Email is verified");
            //   await Fire.updateUserDataRoute(`${user.uid}`, { emailVerified: true })
            //   navigation.navigate('Birthday');
            //   return;
            // }
          
            // User is logged in but hasn't verified their email
            // if (!user.emailVerified && !phase["three"]) {
            //   console.log("User is logged in but hasn't verified their email");
            //   setCurrentPhase("three");
            //   const result = await confirmDecision(
            //     "We need to verify your email!",
            //     `Check the inbox of:\n${userData.email}\nto verify, be sure to check your spam box.\n\nShould we send you another verification email?`
            //   );
          
            //   if (!result) return;
          
            //   Fire.sendEmailVerification(user)
            //     .then(() => {
            //       console.log("Successfully sent verification email");
            //     })
            //     .catch((error) => {
            //       catchErrorForSentry(error);
            //       console.log("Error sending verification email! - useEffect");
            //     });
            // }
        });
          
      
        //Remember Me Cache
        const checkForSavedLogin = async () => {
            const savedLogin = await AsyncStorage.getItem('userLogin');
            if (savedLogin) {
              const { email, password } = JSON.parse(savedLogin);
              // Auto-fill the login form or auto-login the user
              setState((prevState: any) => ({ ...prevState, email, password }));
              setRememberMe(true);
              // If you want to auto-login, call your login function here
            }
        };
        checkForSavedLogin();
        // Setting up event listeners
        const keyboardDidHideSubscription = Keyboard.addListener(
            'keyboardDidHide',
            handleKeyboardHidden
        );
    
        const keyboardDidShowSubscription = Keyboard.addListener(
            'keyboardDidShow',
            handleKeyboardShown
        );
    
        return () => {
            unsubscribe();
            keyboardDidHideSubscription.remove();
            keyboardDidShowSubscription.remove();
        };
    }, []);

    const handleKeyboardHidden = ()=>{
        setKeyboardShown(false);
        // setModalSize(DEFAULT_MODAL_SIZE);
    }

    const handleKeyboardShown = (e: any)=>{
        setKeyboardShown(true);
        if(e){
            // setModalSize( (DEFAULT_MODAL_SIZE - e.endCoordinates.height - Header));
            // setModalSize( (DEFAULT_MODAL_SIZE + Header - e.endCoordinates.height));
        }
    }

    const signInForm: (InputInterface | ValidateInputInterface)[] = [
        // {
        //     name: 'First Name',
        //     errorMessage: state.firstNameError,
        //     errorName: 'nameFlag',
        //     onInputChange: (value: string)=>setState((prevState: any)=>{prevState.firstName = value; return prevState;}),
        //     regex: regexPatterns.name,
        //     defaultValue: state.firstName || '',
        // },
        // {
        //     name: 'Last Name',
        //     errorMessage: state.firstNameError,
        //     errorName: 'nameFlag',
        //     onInputChange: (value: string)=>setState((prevState: any)=>{prevState.lastName = value; return prevState;}),
        //     regex: regexPatterns.name,
        //     defaultValue: state.lastName || '',
        // },
        // {
        //     name: 'Username',
        //     errorMessage: state.usernameError,
        //     errorName: 'nameFlag',
        //     onInputChange: (value: string)=>setState((prevState: any)=>{prevState.username = value; return prevState;}),
        //     regex: regexPatterns.username,
        //     defaultValue: state.username || '',
        // },
        {
            name: 'Email',
            errorMessage: state.emailError,
            errorName: 'emailErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.email = value; return prevState;}),
            regex: regexPatterns.email,
            defaultValue: state.email || '',
        },
        {
            name: 'Password',
            errorMessage: state.passwordError,
            errorName: 'passwordErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.password = value; return prevState;}),
            regex: regexPatterns.password,
            defaultValue: state.password || '',
            props: {
                secureTextEntry: true
            },
        },
    ]

    const signUpForm = [
        {
            name: 'Email',
            errorMessage: state.emailError,
            errorName: 'emailErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.email = value; return prevState;}),
            regex: regexPatterns.email,
            defaultValue: state.email || '',
        },
        {
            name: 'Password',
            errorMessage: state.passwordError,
            errorName: 'passwordErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.password = value; return prevState;}),
            regex: regexPatterns.password,
            defaultValue: state.password || '',
            props: {
                secureTextEntry: true
            },
        },
        {
            name: 'Username',
            errorMessage: state.usernameError,
            errorName: 'nameFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.username = value; return prevState;}),
            regex: regexPatterns.username,
            defaultValue: state.username || '',
        },
        {
            name: 'Zipcode',
            errorMessage: state.zipcodeError,
            errorName: 'zipcodeErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.zipcode = value; return prevState;}),
            regex: regexPatterns.zipcode,
            defaultValue: state.zipcode || '',
            props: {
                secureTextEntry: false,
            },
        },
    ]

    const renderChooseAccountTitle = ()=>{
        return(
            <View style={{
                // flex: 1,
                position: 'absolute',
                bottom:0,
                right: 0,
                left: 0,
                flexDirection: 'column',
                alignItems: 'center',
                zIndex: 1,
                // borderColor: 'green',
                // borderWidth: 1
            }}>
                {!keyboardShown && <AlwaysLocalLogo/>}
                <View>
                    <Text style={{fontSize: scaleFontSize(25), alignSelf: 'center', fontWeight: '400'}}>
                        WELCOME TO:
                    </Text>
                    <Text style={{fontWeight: '500', fontSize: scaleFontSize(30)}}> ALWAYS <Text style={{color: COLORS.primaryRed}}>LOCAL</Text></Text>
                </View>
            </View>
        )
    }

    const createAccountWithEmail = () => {
        const { username, password, zipcode } = state;
        let { email, phoneNumber } = state;
        setIsSigningUp(true);
    
        Fire.createUserWithEmailAndPassword(email, password)
          .then((userCredential) => {
            var user = userCredential.user;
            email = email.trim();
            let userData = generateInitUserData(state, user, funnelState.toLowerCase());
            initDatabaseUserData(userData);
            dispatch(setUserData(userData));
            setProcessing(false);
            // setIsSigningUp(false);
            navigation.navigate('Birthday');
          })
          .catch((error) => {
            catchErrorForSentry(error);
            setIsSigningUp(false);
          });
      };

    const initDatabaseUserData=(userData: any)=>{
        const {uid: userId, phoneNumber} = userData;
        let strippedUserData = {...userData}
        delete strippedUserData.phoneNumber;

        Fire.updateRoute(`publicUserData/`, {[`${userId}`]: strippedUserData});
        // Fire.updateRoute(`phoneNumbers/`, {[`${userId}`]: phoneNumber});
    }

    const isFormDataValid = ()=>{
        const {firstName, lastName, email, password, phoneNumber, emailErrorFlag, phoneNumberErrorFlag, passwordErrorFlag} = state;
        if(firstName || lastName || email || password || phoneNumber) return true;
        if(!emailErrorFlag || !passwordErrorFlag || !phoneNumberErrorFlag) return true;
    }

    const onSubmitCredentials = ()=>{
        if(!isFormDataValid()) return alert("There is a problem with your information");
        const { email, username } = state;
        setProcessing(true);
        isAccountInformationTaken("", email, username)
        .then(async (response: any)=>{
            console.log("Response: ", response);
            if(!response){
                setProcessing(false);
                return;
            };
            let { isPhoneNumberTaken, isUsernameTaken, isEmailTaken } = JSON.parse(response);

            if(isPhoneNumberTaken || isUsernameTaken || isEmailTaken){
                const alertText = (()=>{
                if(isPhoneNumberTaken) return 'There is already an account with that phone number'
                if(isUsernameTaken) return 'There is already an account with that username'
                if(isEmailTaken) return 'There is already an account with that email'
                })()
                setProcessing(false);
                alert(alertText);
                return;
            }
            else{
                setShowPasswordModal(true);
            }
        })
        .catch((error)=>{
            setProcessing(false);
            alert("There was an error checking if your credentials are already taken - Please try again");
            console.log("Error creating account EntryScreen: ", error);
            console.log("Error creating account EntryScreen: ", JSON.stringify(error)); 
            catchErrorForSentry(error);
        })
    }

    const SignUpHeader = ()=>{
        return(
            <ImageBackground
                resizeMode={'cover'}
                style={{
                    zIndex: 0,
                    flex: Platform.OS === 'ios' ? (keyboardShown ? .5/3 : 1/3) : 1/3,
                    width: '100%',
                    height: '100%',
                    justifyContent: 'flex-end',
                    // borderColor: 'red',
                    // borderWidth: 1,
                    // transform: [{ perspective: 200 }, { rotateX: '45deg' }]
                }}
                source={require('../assets/LoginMap.jpg')}
            >
                <LinearGradient
                    colors={['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 1)']}
                    style={{flex: 1}}
                />
                {renderChooseAccountTitle()}
                {/* <AlwaysLocalLogo style={{top:0, width: '35%', height: '75%'}}/> */}
            </ImageBackground>
        )
    }

    const LoginButton = ()=>{
        return(
            <TouchableContainer
                onPress={onLoginPress}
                style={{
                    ...styles.accTypeBtn,
                    height: Platform.OS === 'ios' ? '12.5%' : (keyboardShown ? '25%' : '12.5%'),
                    // height: '12.5%',
                    width: '45%',
                    alignSelf: 'center',
                    flexDirection: 'row',
                    marginTop: Platform.OS === 'ios' ? 10 : 0
            }}>
                    <FontAwesome5 style={{
                        // borderWidth: 1,
                        // borderColor: 'white',
                        marginRight: 5,
                        alignSelf: 'center',
                        transform: [{ rotate: '45deg' }]}}
                        name="location-arrow"
                        size={30}
                        color="white"
                    />
                    <Text style={{
                        ...styles.accTypeBtnTxt,
                        marginLeft: 5,
                        fontSize: scaleFontSize(30),
                        textAlignVertical: 'center'
                    }}>
                        GO!
                    </Text>
            </TouchableContainer>
        )
    }

    const onContinueAsAGuest = ()=>{
        // console.log("Navigation: ", navigation);
        // setProcessing(true);
        navigation.navigate('Home');
        // setProcessing(false);
    }

    const onLoginPress = async ()=> {
        setProcessing(true);
        const email = state.email.trim();
        const password = state.password;

        // await Fire.setPersistence();
        // console.log("Setting persistent state")
        Fire.signInWithEmailAndPassword(email, password)
        .then(async (userCredential)=>{

            setProcessing(false);
            const userData = await Fire.getUserData(userCredential.user.uid);
            await AsyncStorage.setItem('userLogin', JSON.stringify({ email: userData.email, password }));
            dispatch(setUserData(userData));
            // console.log("Sending user to home")
            navigation.navigate('Home');
        })
        .catch((error)=>{
            console.log("Error: ", error);
            console.log("Error signing in user JSON: ", JSON.stringify(error)); 
            catchErrorForSentry(error);
            if(error.code === 'auth/user-not-found'){
                alert("User email not found!");
            }
            else if(error.code === 'auth/wrong-password'){
                alert("Your password is incorrect");
            }
            else if(error.code === 'auth/invalid-email'){
                alert("Your email is invalid");
            }
            else if(error === 'Error: Location provider is unavailable. Make sure that location services are enabled.'){
                alert("Location provider is unavailable. Make sure that your location services are enabled.");
            }
            else{
                alert("Strange error while trying to sign you in occurred - Please email ty@tyfoodsforthought.com for assistance");
            }
            console.log(`Authentication failed with this error: ${error}`)
            setProcessing(false);
        })
            
    }

    const PhaseButton = (props: any)=>{
        const {
            style,
            next=true
        } = props;

        return(
            <TouchableContainer
                onPress={async ()=>{
                    if(state.passwordErrorFlag) return alert("Your password must be 6 characters or longer")
                    if(next){
                        if(phase.three) console.log("Sending to main app");
                        else if(phase.two){
                            const {username, email, password, zipcode} = state;
                            const isZipInFlorida = await isZipCodeInFlorida(zipcode);
                            console.log("is Zip in Florida: ", isZipInFlorida)
                            if(!isZipInFlorida) return alert("Sorry, this app is currently only available in Florida!")
                            if(!username) return alert("You haven't entered a username!");
                            if(!email) return alert("You haven't entered an email!");
                            if(!password) return alert("You haven't entered a password!");
                            onSubmitCredentials();
                        }
                    }
                    else{
                        if(phase.three) setCurrentPhase("two");
                        else if(phase.two){
                            setState((prevState: any)=>{
                                return {
                                    ...prevState,
                                    username: "",
                                    password: "",
                                    email: "",
                                    passwordErrorFlag: false,
                                    usernameErrorFlag: false,
                                    emailErrorFlag: false,
                                }
                            })
                            setCurrentPhase("one");
                        }
                    }
            }}>
                <View style={{
                    ...styles.accTypeBtn,
                    height: '12.5%',
                    width: '35%',
                    padding: 10,
                    alignSelf: 'center',
                    flexDirection: next ? 'row' : 'row-reverse',
                    justifyContent: 'space-around', 
                    alignItems: 'center',
                    ...style
                }}>
                    {/* <Text style={{
                        ...styles.accTypeBtnTxt,
                        fontSize: scaleFontSize(25),
                        textAlignVertical: 'center',
                    }}>
                        {next ? "Next" : "Back"}
                    </Text> */}
                    <FontAwesome5 style={{
                        top: next ? 1 : 2,
                        // borderWidth: 1,
                        // borderColor: 'white',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignSelf: 'center',
                        transform: [{ rotate: next ? '45deg' : '225deg' }]}}
                        name="location-arrow"
                        size={30}
                        color="white"
                    />
                </View>
            </TouchableContainer>
        )
    }

    const setCurrentPhase = (phase: string)=>{
        setPhase(()=>{
            return {
                one: phase === "one",
                two: phase === "two",
                three: phase === "three",
            }
        })
    }
    const GuestButton = ()=>{
        return(
            <TouchableContainer 
                style={{
                    alignSelf: 'center',
                    justifyContent: 'flex-start'
                }}
                onPress={onContinueAsAGuest}>
                <Text style={{
                    fontSize: scaleFontSize(11),
                    fontWeight: '500',
                    textAlign: 'center',
                    // borderBottomWidth: 1,
                    // borderBottomColor: 'blue',
                    color: 'blue',
                }}> Continue as a guest </Text>
        </TouchableContainer>
        )
    }
    const RegisterButton = ()=>{
        return(
            <TouchableContainer 
                style={{
                    alignSelf: 'center',
                }}
                onPress={()=>setCurrentPhase("two")}>
                    <Text style={{
                        fontSize: scaleFontSize(15),
                        fontWeight: '500',
                        textAlign: 'center'
                    }}> Not a local? </Text>
                    <Text style={{
                        fontSize: scaleFontSize(13),
                        fontWeight: '500',
                        textAlign: 'center',
                        borderBottomWidth: 1,
                        borderBottomColor: 'black',
                    }}> REGISTER NOW </Text>
            </TouchableContainer>
        )
    }
    const doesPasswordMatch = ()=>{
        if(state.password !== state.confirmPassword) return false;
        return true;
    }
    const onConfirmPassword = ()=>{
        if(!doesPasswordMatch()) return alert("Password doesn't match!");
        else{
            console.log("Password confirmed");
            setShowPasswordModal(false);
            createAccountWithEmail()
        }
    }
    const ProcessingView = ()=>{
        return(
            <SafeAreaView edges={EDGES.ALL} style={{flex: 1, backgroundColor: '#FFF'}}>
                <View style={{flex: 1, justifyContent: 'center', alignContent: 'center'}}>
                    <ActivityIndicator color={COLORS.primaryRed} size={'large'}/>
                </View>
            </SafeAreaView>
        )
    }
    const reloadApp = async ()=>{
        const result = await confirmDecision("Refreshing app", "If you've already verified your email, refresh the app to continue");
        if(result) await Updates.reloadAsync();
    }
    const { top, bottom} = useSafeAreaInsets()
    return(
        <Screen style={{
            paddingBottom: isDeviceOld ? 0 : bottom,
            ...(Platform.OS === 'android' ? {marginTop: 0, paddingTop: top} : {})
        }}>
            <CustomModal
                gradient={false}
                cardStyle={{
                    height: '50%',
                    borderBottomRightRadius: 10,
                }}
                containerStyle={{
                    justifyContent: 'center',
                    alignContent: 'center',
                }}
                title={"Confirm your password"}
                visibilityFlag={showPasswordModal}
                visibilityControlFunc={setShowPasswordModal}
                showBack={true}
                onSubmit={onConfirmPassword}
                onBack={()=>{
                    setShowPasswordModal(false);
                    setProcessing(false);
                }}
            >
                <ValidateInput

                    style={{
                        width: '90%',
                        alignSelf: 'center',
                        backgroundColor: COLORS.primaryGray,
                    }}
                    showLabel={false}
                    inputStyle={{
                        backgroundColor: COLORS.primaryGray,
                        borderColor: 'black',
                        borderWidth: 1,
                        borderRadius: 5,
                    }}
                    secureTextEntry={true}
                    name="Password"
                    errorMessage={state.passwordError}
                    errorName={'passwordErrorFlag'}
                    onInputChange={(value: string)=>setState((prevState: any)=>{prevState.confirmPassword = value; return prevState;})}
                    regex={regexPatterns.password}
                    defaultValue={state.confirmPassword || ''}
                />
            </CustomModal>
            <TouchableContainer onPress={()=>{Keyboard.dismiss()}} noFeedback={true} disabled={Platform.OS !== 'ios' ? true : (keyboardShown) ? false : true}style={{flex: 1}}>
                {
                    processing ?
                    <ProcessingView/> :
                    <View style={styles.container}>
                        {( phase.one || phase.two || phase.three && !keyboardShown) && <SignUpHeader/>}
                        <View  style={{
                            flex: phase.one ? (keyboardShown ? 2.5/3 : 2/3) : (keyboardShown ? 1 : 2/3),
                            width: '90%',
                            justifyContent: Platform.OS === 'ios' ? 'flex-start' : 'space-around',
                            // borderColor: 'red',
                            // borderWidth: 1,
                        }}>
                            {/* {phase.three && (
                                userData && userData.emailVerified && !edittingEmail ?
                                <Card style={{
                                    width: '90%',
                                    height: '75%',
                                    alignSelf: 'center',
                                    // borderColor: 'red', borderWidth: 1,
                                    justifyContent: 'center', 
                                    alignItems: 'center'}}
                                >
                                    <Text style={{
                                        //TODO: This is weird, but seems to work for styling.
                                        borderColor: 'transparent', borderWidth: 1,
                                        textAlign: 'center',
                                        fontWeight: '500',
                                        fontSize: scaleFontSize(15)}}>
                                        A verification email has been sent to your email address
                                    </Text>
                                    <Text style={{textAlign: 'center', fontSize: scaleFontSize(12)}}>
                                        Please verify your email to proceed!
                                    </Text>
                                    <TouchableContainer style={{marginTop: 10, width: 200}} onPress={()=>{
                                        if(!user) return;
                                        Fire.sendEmailVerification(user)
                                        .then(()=>{
                                            console.log("Successfully sent verification email");
                                        })
                                        .catch((error: any)=>{
                                            catchErrorForSentry(error);
                                            console.log("Error sending verification email!");
                                        })
                                    }}
                                    >
                                        <Card style={{padding: 10}}>
                                            <Text style={{textAlign: 'center', fontSize: scaleFontSize(12)}}>
                                                {`Resend email to ${userData.email}`}
                                            </Text>
                                        </Card>
                                    </TouchableContainer>
                                    <TouchableContainer style={{marginTop: 10, width: 200}} onPress={()=>{setEdittingEmail(true)}}>
                                        <Card style={{padding: 10}}>
                                            <Text style={{textAlign: 'center', fontSize: scaleFontSize(15)}}>
                                                {`Modify email`}
                                            </Text>
                                        </Card>
                                    </TouchableContainer>
                                </Card>
                                :
                                <Card style={{
                                    width: '90%',
                                    height: '75%',
                                    alignSelf: 'center',
                                    // borderColor: 'red', borderWidth: 1,
                                    justifyContent: 'center', 
                                    alignItems: 'center'}}
                                >
                                    <EmailEditor containerStyle={{width: '95%'}} />
                                    <TouchableContainer style={{marginTop: 10, width: 200}} onPress={()=>{setEdittingEmail(false)}}>
                                        <Card style={{padding: 10}}>
                                            <Text style={{textAlign: 'center', fontSize: scaleFontSize(15)}}>
                                                {`Back`}
                                            </Text>
                                        </Card>
                                    </TouchableContainer>
                                </Card>
                            )} */}
                            {
                                phase.one
                                && <SignUpSheet
                                    containerStyle={{
                                        ...styles.sheet,
                                    }} inputs={signInForm} state={state} setState={setState} />}
                            {phase.two && 
                            <>
                                <Text onPress={()=>{
                                Linking.openURL(ensureProtocol('https://app.alwayslocal.net/signup'));
                            }}
                            style={{width: '75%', alignSelf: 'center', fontSize: scaleFontSize(12), textAlign: 'center'}}>
                                    Note: Establishments must make their account at
                                    <Text style={{color: 'blue', textDecorationLine: 'underline', textDecorationColor: 'blue', fontWeight: '500'}}> this link</Text>
                                </Text>
                                <SignUpSheet inputs={signUpForm} state={state} setState={setState} />
                            </>
                            }
                            {phase.one &&
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                                    <CheckBox
                                        boxType={"square"}
                                        value={rememberMe}
                                        onValueChange={setRememberMe}
                                    />
                                    <Text style={{ marginLeft: 8 }}>Remember Me</Text>
                                </View>
                            }
                            {phase.one && <LoginButton/>}
                            {/* { (phase.one && !keyboardShown) && <GuestButton/>}
                            { (phase.one && !keyboardShown) && <RegisterButton/>} */}
                            { (phase.one && !keyboardShown) &&(
                                <View style={{
                                    alignSelf:'center',
                                    flex:1,
                                    padding: 10,
                                    width: '45%',
                                    justifyContent: 'space-between',
                                    // borderColor: 'black',
                                    // borderWidth: 1
                                }}>
                                    <RegisterButton/>
                                    <GuestButton/>
                                </View>
                            )}
                            {(phase.two) &&
                                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-around', marginTop: 15}}>
                                    <PhaseButton style={{
                                        height: Platform.OS === 'ios' ? '35%': (phase.two && keyboardShown ? '60%' : '35%'),
                                        minWidth: 75,
                                        maxHeight: 75,
                                    }}
                                    next={false}/>
                                    <PhaseButton style={{
                                        height: Platform.OS === 'ios' ? '35%': (phase.two && keyboardShown ? '60%' : '35%'),
                                        minWidth: 75,
                                        maxHeight: 75,
                                    }}
                                    next={true}/>
                                </View>
                            }
                        </View>
                    </View>
                }
            </TouchableContainer>
        </Screen>
    )
}

const styles = StyleSheet.create({
    container:{
        flex: 1,
        backgroundColor: '#FFF',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        // borderColor: 'red',
        // borderWidth: 1,
    },
    accTypeBtn:{
        borderRadius: 10,
        backgroundColor: '#DD222E',
        width: 150,
        height: 70,
        justifyContent: 'center',
    },
    accTypeBtnTxt:{
        fontSize: scaleFontSize(20),
        alignSelf: 'center',
        color: 'white',
        fontWeight: "500",
    },
    signUpForm: {
        // flex: 1,
        height: '80%',
        width:'80%',
    },
    sheet: {
        marginVertical: Platform.OS === 'ios' ? 0 : 15,
        // borderColor: 'red',
        // borderWidth: 1,
    }
})

export default SignUpScreen